import React, { useEffect, useState } from "react";
import TopNavigationBar from "../TopNavigationBar";
import {Button, Container, Card, Placeholder, Alert} from "react-bootstrap";
import ReactCodeInput from "react-verification-code-input";
import { useParams } from "react-router";
import { BASE_URL } from "../Util";

export default function ValidationController(props)
{
    const { session_id } = useParams();
    const initialState = {message: null, errorMessage: null, enableSignIn:false, doneLoading:false, validationCode: null};
    const [{message, errorMessage,enableSignIn, doneLoading, validationCode},setState] = useState(initialState);

    console.log("session_id: " + session_id);

    const fetchMessage = () => {
        const url = BASE_URL + `users/validate/message/${session_id}/`;
        console.log("GET " + url);
        fetch(url,
        ).then((resp) => resp.json())
            .then((data) => {
                if(data['success']) {
                    setState( prevState => ({...prevState,message: data["message"],doneLoading:true}));
                }
                else {
                    setState( prevState => ({...prevState,errorMessage: data["message"],doneLoading:true}));
                }
                console.log(data);
            });
    }

    const handleChange = (values) => {
        if( values.length >= 6 ) {
            console.log("values: " + values);
            setState(prevState => ({...prevState, enableSignIn:true, validationCode: values}));
        }
        else {
            setState(prevState => ({...prevState, enableSignIn:false}));
        }
    }

    const handleValidateCode = () => {
        if( validationCode ) {
            // TODO: check validation code

            // reset password?

            // navigate
        }
    }

    useEffect( () => {
        if(!message && !doneLoading) {
            fetchMessage();
        }
    })

    let validationMessage = <p className="validation_code_p"><Placeholder style={{width: '100%'}} /><Placeholder style={{width: '25%'}} /></p>;
    let validationCodeInput = <ReactCodeInput fieldWidth={48} onChange={handleChange} className="validation_code_input" />;
    let validationButton = <Button variant='secondary' disabled>Continue</Button>
    if( enableSignIn ) {
        validationButton = <Button onClick={handleValidateCode} variant='success'>Continue</Button>
    }
    
    
    if ( errorMessage ) {
        validationCodeInput = <ReactCodeInput disabled fieldWidth={48} onChange={handleChange} className="validation_code_input" />;
        validationMessage = <Alert variant="danger">{errorMessage}</Alert>
    }
    else if( message ) {
        validationMessage = <p className="validation_code_p">{message}</p>
    }

    return(

        <div>
            <TopNavigationBar restrict="login" />
            <Container className="login_page">
                <Card style={{ maxWidth: '30rem' }} className="login_card">
                    <Card.Header>Enter validation code</Card.Header>
                    <Card.Body>
                        {validationMessage}
                        {validationCodeInput}
                    </Card.Body>
                    <Card.Footer className="validation_code_footer">{validationButton}</Card.Footer>
                </Card>
            </Container>
        </div>
    );
}