import React, { Component, Fragment, useState } from 'react';
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { BASE_URL } from '../Util';


function AddressAutocomplete(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);

    let session_token = localStorage.getItem("session_token")

    const handleSearch = (query) => {
        setIsLoading(true);
        console.log("handleSearch: " + query);
        let encodedQuery = encodeURIComponent(query);

        fetch(BASE_URL + "autocomplete-address/?q=" + encodedQuery, {
            mode: "cors", headers: {
                "X-Token": session_token
            }
        })
            .then((resp) => resp.json())
            .then((data) => {
                setOptions(data);
                setIsLoading(false);
            });
    };

    // Bypass client-side filtering by returning `true`. Results are already
    // filtered by the search endpoint, so no need to do it again.
    const filterBy = () => true;
    return (
        <AsyncTypeahead
            filterBy={filterBy}
            id="location_autocomplete"
            isLoading={isLoading}
            labelKey="label"
            minLength={5}
            onChange={props.handleSelect}
            onSearch={handleSearch}
            options={options}
            placeholder="Enter address..."
            renderMenuItemChildren={(option, props) => (
                <Fragment>
                    <span>{option.label}</span>
                </Fragment>
            )}
        />
    );
}

export default class ViewLoad extends Component {
    constructor(props) {
        super(props);
        /*
        const extractDefaultValue = (defaultFormValues,controlId) => {
            if( defaultFormValues['controlId'] != null ) {
                return defaultFormValues["controlId"];
            }
            else {
                return "";
            }
        }
        */

        this.state = {
            fields: [
                {
                    "label": "Pickup Location",
                    "value": "",
                    "type": "location",
                    "controlId": "pickup",
                    "editable": !props.viewOnly,
                    "editing": false
                },
                {
                    "label": "Delivery Location",
                    "value": "",
                    "type": "location",
                    "controlId": "delivery",
                    "editable": !props.viewOnly,
                    "editing": false
                },
                {
                    "label": "Weight (lbs)",
                    "value": "",
                    "type": "number",
                    "controlId": "weight",
                    "editable": !props.viewOnly,
                    "editing": false
                },
                {
                    "label": "Rate",
                    "value": "",
                    "type": "number",
                    "controlId": "rate",
                    "editable": !props.viewOnly,
                    "editing": false
                },
                {
                    "label": "Length",
                    "value": "",
                    "type": "number",
                    "controlId": "length",
                    "editable": !props.viewOnly,
                    "editing": false
                },
                {
                    "label": "Description",
                    "value": "",
                    "type": "string",
                    "controlId": "description",
                    "editable": !props.viewOnly,
                    "editing": false
                }],
            currentControlValue: "",
            currentControlId: "",
            currentControlPlaceId: "",
            calculatedDistance: -1,
        };

        if( props.defaultFormValues ) {
            this.state.calculatedDistance = props.defaultFormValues["distance"];
            for( let each in props.defaultFormValues ) {
                for( let x in this.state.fields ) {
                    if( this.state.fields[x]["controlId"] === each) {
                        this.state.fields[x]["value"] = props.defaultFormValues[each];
                        break;
                    }
                }
            }
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleTypeaheadSelect = this.handleTypeaheadSelect.bind(this);
    }

    handleTypeaheadSelect(items) {
        if (items.length > 0) {
            let select_item = items[0];
            this.setState({ currentControlValue: select_item.label, currentControlPlaceId: select_item.place_id });
        }
    }

    updateLocation(controlId) {
        const session_token = localStorage.getItem("session_token");
        const place_id = this.state.currentControlPlaceId;
        console.log("updateLocation: " + place_id);
        fetch(BASE_URL + "lookup-place/?place_id=" + place_id, {
            mode: "cors", headers: {
                "X-Token": session_token
            }
        })
            .then((resp) => resp.json())
            .then((data) => {
                let fields = this.state.fields;
                for (let each in fields) {
                    if (fields[each]["controlId"] === controlId) {
                        let locationValue = {
                            ...data.data,
                            "place_id": place_id
                        }
                        fields[each]["value"] = locationValue;
                        this.setState({ fields: fields });
                        this.updateMap();
                        break;
                    }
                }
            });
    }

    updateMap() {
        console.log("updating map...");
        let pickup = null;
        let delivery = null;
        for (let x in this.state.fields) {
            if (this.state.fields[x].controlId === "pickup" && this.state.fields[x].value !== "") {
                pickup = this.state.fields[x].value;
                console.log(JSON.stringify(pickup));
            }
            else if (this.state.fields[x].controlId === "delivery" && this.state.fields[x].value !== "") {
                delivery = this.state.fields[x].value;
                console.log(JSON.stringify(delivery));
            }
        }
        if (pickup && delivery) {
            console.log("calculating distance...");
            console.log(pickup);
            console.log(delivery);
            const session_token = localStorage.getItem("session_token");
            fetch(BASE_URL + "calculate-distance/?origin=" + pickup.place_id + "&dest=" + delivery.place_id, {
                mode: "cors", headers: {
                    "X-Token": session_token
                }
            }).then((resp) => resp.json()).then((data) => {
                this.setState({ calculatedDistance: data.meters });
                console.log("calculated distance: " + this.state);
                this.props.updateFormState(this.state);
            });
        }
        else {
            this.setState({ calculatedDistance: -1 });
            this.props.updateFormState(this.state);
        }
    }

    handleChange(event) {
        this.setState({ currentControlId: event.target.id, currentControlValue: event.target.value });
    }

    handleSave() {
        console.log("Saving...");
    }

    setEditField(controlId) {
        let fields = this.state.fields;
        for (let x in fields) {
            if (fields[x]["controlId"] === controlId) {
                fields[x]["editing"] = true;
            }
            else {
                fields[x]["editing"] = false;
            }
        }
        this.setState({ fields: fields, currentControlId: controlId });
    }

    updateValue(controlId, value) {
        let fields = this.state.fields;
        console.log("controlId: " + controlId);
        console.log("value" + value);

        for (let x in fields) {
            if (fields[x]['controlId'] === controlId) {
                fields[x]['value'] = value;
                fields[x]['editing'] = false;
                if (fields[x]['type'] === "location") {
                    this.updateLocation(controlId);
                }
                break;
            }
        }
        this.setState({ fields: fields });
        this.props.updateFormState(this.state);
        //this.setState({currentControlValue:"", currentControlId: "", currentControlPlaceId:null});
    }

    render() {
        let formGroups = [];
        for (let eachItem of this.state.fields) {
            let editButton = "";

            if (eachItem["editable"]) {
                editButton = <Button variant="secondary" onClick={() => { this.setEditField(eachItem['controlId']); }}>Edit</Button>;
            }
            let valueControl = "";
            if (eachItem["editing"]) {
                if (eachItem["type"] === "location") {
                    console.log("location type");
                    valueControl = <AddressAutocomplete handleSelect={this.handleTypeaheadSelect} />;
                    //valueControl = <Form.Control onChange={(e) => {this.autocompleteAddress(e.target.value);}} defaultValue={eachItem["value"]} />;
                }
                else {
                    console.log("default type");
                    valueControl = <Form.Control id={eachItem['controlId']} onChange={this.handleChange} defaultValue={eachItem["value"]} />;
                }
                editButton = <Button variant="success" onClick={() => { this.updateValue(this.state.currentControlId, this.state.currentControlValue) }}>Done</Button>;
            }
            else {
                if (eachItem["type"] === "location") {
                    if (eachItem["value"]["formatted_address"] !== undefined) {
                        valueControl = <Form.Control plaintext readOnly defaultValue={eachItem["value"]["formatted_address"]} />;
                    }
                    else {
                        valueControl = <Form.Control plaintext readOnly defaultValue="" />;
                    }
                }
                else {
                    valueControl = <Form.Control plaintext readOnly defaultValue={eachItem["value"]} />;
                }

            }
            formGroups.push(
                <Form.Group className="load-form-group form-row" as={Row}>
                    <Col sm="auto">
                        <Form.Control plaintext readOnly defaultValue={eachItem["label"]} />
                    </Col>
                    <Col sm="7">
                        {valueControl}
                    </Col>
                    <Col sm="auto"  className="align-items-end" >{editButton}</Col>
                </Form.Group>
            );
        }

        let alerts = [];
        for( let each of this.props.alerts ) {
            alerts.push(<Alert variant="warning">{each}</Alert>);
        }
        
        //<Form className="view-load-form">{formGroups}</Form>
        return (<div>{alerts}
            {formGroups}
        </div>);
    }
}