import React, {useState} from "react";
import {Form, Container, Card, Row, Col, Button, Alert} from "react-bootstrap";

import { BASE_URL, validatiateCompanyName } from "../Util";

import TopNavigationBar from "../TopNavigationBar";
import { useNavigate } from "react-router";

export default function SetMotorCarrier(props) {
    const initialState = {continueEnabled: false, motorCarrier:"", message: null};
    const [{continueEnabled, motorCarrier, message}, setState] = useState(initialState);

    const navigate = useNavigate();

    let submitButton = <Button disabled variant="primary" type="submit">Continue</Button>;
    if(continueEnabled) {
        submitButton = <Button variant="primary" type="submit">Continue</Button>;
    }

    let alertMessage = "";

    if( message ) {
        alertMessage = <Alert variant="warning">{message}</Alert>
    }
    if( motorCarrier.length > 0 && !validatiateCompanyName(motorCarrier) ) {
        alertMessage = <Alert variant="warning">Company name must start with a capital letter, contain 5-35 letters, spaces and digits.</Alert>
    }

    

    const onFormSubmit = e => {
        e.preventDefault();

        const session_token = localStorage.getItem("session_token");

        fetch(BASE_URL + "account/set_company_name/", {
            method:"POST",
            mode:"cors",
            headers: {"X-Token": session_token},
            body: motorCarrier
        }).then(response => response.json()).then(data => {
            if( data.success ) {
                navigate("/loads");
            }
            else {
                setState(prevState => ({...prevState, message:data["message"], motorCarrier:"" }));
            }
        })
    }

    return (
        <div>
            <TopNavigationBar restrict="login" />
            <Container className="login_page">
                <Card style={{ maxWidth: '30rem' }} className="login_card">
                    <Card.Header><h5>Finish setup</h5>
                    </Card.Header>
                    <Form onSubmit={onFormSubmit}>
                        <Card.Body>
                            <p>The Motor Carrier name identifies your organization. This can be the legal name of your LLC or anything.</p>
                            {alertMessage}
                            <Form.Group as={Row} className="mb-3" controlId="motorCarrier">
                                <Form.Label column>Motor Carrier</Form.Label>
                                <Col sm="8">
                                    <Form.Control value={motorCarrier} onChange={(action) => {
                                        let companyName = action.target.value;
                                        if( validatiateCompanyName(companyName) ) {
                                            setState(prevState => ({ ...prevState, motorCarrier: action.target.value, continueEnabled: true }));
                                        }
                                        else {
                                            setState(prevState => ({ ...prevState, motorCarrier: action.target.value, continueEnabled: false }))
                                        }

                                    }} />
                                </Col>
                            </Form.Group>
                            <Container>
                                <Row className="justify-content-md-center">
                                    {submitButton}
                                </Row>
                            </Container>
                        </Card.Body>
                    </Form>
                </Card>
            </Container>
        </div>
    );
}
