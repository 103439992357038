import React, { useEffect, useState } from "react";
import { Modal, Button, Spinner, Placeholder, Form, Row, Col } from "react-bootstrap";
import { BASE_URL } from "../Util";
import ViewLoad from "./ViewLoad";

function validateFormData(formData) {
    console.log("validateFormData");
    console.log(formData);
    let validationAlerts = [];
    try {
        JSON.parse(formData.pickup_json);
    }
    catch {
        validationAlerts.push("Pickup address required.");
    }
    if (formData.delivery_json === "") {
        validationAlerts.push("Delivery address required.");
    }
    const formWeight = parseInt(formData.weight);
    const formRate = parseInt(formData.rate);
    const formLength = parseInt(formData.length);
    if (isNaN(formWeight)) {
        validationAlerts.push("Weight is required.");
    }
    else {
        formData.weight = formWeight;
    }
    if (isNaN(formRate)) {
        validationAlerts.push("Rate is required.");
    }
    else {
        formData.rate = formRate;
    }
    if (isNaN(formLength)) {
        formData.length = null;
    }

    return { formData: formData, alerts: validationAlerts };
}

function ViewLoadPlaceholder(props) {
    let placeholderFormGroups = [];
    for (let x = 0; x < props.count; x++) {
        placeholderFormGroups.push(<Form.Group key={x} className="load-form-group form-row" as={Row}>
            <Col sm="3">
                <Placeholder sm="7" />
            </Col>
            <Col sm="7">
                <Placeholder sm="7" />
            </Col>
            <Col sm="auto" className="align-items-end" ><Placeholder.Button variant="secondary">Edit</Placeholder.Button></Col>
        </Form.Group>);
    }

    return <div>{placeholderFormGroups}</div>
}

function LoadModal(props) {
    const initialState = {
        formState: null,
        alerts: [],
        isLoading: true,
        defaultFormValues: null,
        loadId: null
    };
    const [{ formState, alerts, isLoading, defaultFormValues, loadId }, setState] = useState(initialState);

    const closeModal = () => {
        //clearState();
        props.handleCloseModal();
    };

    let url = null;
    if( props.loadId ) {
        url = BASE_URL + "loads/load/" + props.loadId;
    }
    const session_token = localStorage.getItem("session_token");


    const fetchData = async () => {
        if(url) {
            try {
                const response = await fetch(url, {
                    headers: {
                        "X-Token": session_token
                    }
                });
                const json = await response.json();
                console.log(json);
                if (json.success) {
                    setState(prevState => ({ ...prevState, defaultFormValues: json.data, isLoading: false, loadId: props.loadId }));
                }
            }
            catch (error) {
                console.log(error);
                setState(prevState => ({ ...prevState, isLoading: false }));
            }
        }
    };

    const extractFieldValue = (controlId) => {
        for (let x in formState.fields) {
            if (formState.fields[x].controlId === controlId) {
                return formState.fields[x].value;
            }
        }
    }

    const handleUpdate = () => {
        console.log("Updating load: " + props.loadId);

        if (formState != null) {
            const formData = {
                pickup_json: JSON.stringify(extractFieldValue("pickup")),
                delivery_json: JSON.stringify(extractFieldValue("delivery")),
                rate: extractFieldValue("rate"),
                weight: extractFieldValue("weight"),
                length: extractFieldValue("length"),
                description: extractFieldValue("description"),
                distance: formState['calculatedDistance'],
            }
            const validatedFormData = validateFormData(formData);
            setState(prevState => ({ ...prevState, alerts: validatedFormData.alerts }));

            if (validatedFormData.alerts.length === 0) {
                const url = BASE_URL + "loads/load/" + props.loadId + "/";
                const session_token = localStorage.getItem("session_token");
                fetch(url, {
                    method: "PUT",
                    mode: "cors",
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Token': session_token
                    },
                    body: JSON.stringify(validatedFormData.formData)
                }).then(response => response.json())
                    .then(data => {
                        console.log(data);
                        if (data.success) {
                            props.handleCloseModal();
                        }
                        else {
                            const alerts = ["Error connecting to backend."]
                            setState(prevState => ({ ...prevState, alerts: alerts }));
                        }
                    });
            }

        }
    }

    const handleSave = () => {
        console.log("Saving...");

        if (formState != null) {
            let formData = {
                pickup_json: JSON.stringify(extractFieldValue("pickup")),
                delivery_json: JSON.stringify(extractFieldValue("delivery")),
                rate: extractFieldValue("rate"),
                weight: extractFieldValue("weight"),
                length: extractFieldValue("length"),
                description: extractFieldValue("description"),
                distance: formState['calculatedDistance'],
            }
            const validatedData = validateFormData(formData);
            setState(prevState => ({ ...prevState, alerts: validatedData.alerts }));
            if (validatedData.alerts.length === 0) {
                const session_token = localStorage.getItem("session_token");
                const url = BASE_URL + "loads/new/";
                fetch(url, {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Token': session_token
                    },
                    body: JSON.stringify(validatedData.formData)
                }).then(response => response.json())
                    .then(data => {
                        console.log(data);
                        if (data.success) {
                            props.handleNewLoad();
                            props.handleCloseModal();
                        }
                        else {
                            const alerts = ["Error connecting to backend."]
                            setState(prevState => ({ ...prevState, alerts: alerts }));
                        }
                    });
            }

        }
    };

    useEffect(() => {
        if (props.loadId != null && props.loadId !== loadId) {
            console.log("Fetching data for: " + props.loadId);
            fetchData();
        }
    });

    const updateFormState = (data) => { setState(prevState => ({ ...prevState, formState: data })); };

    let viewOnly = false;
    let modalFooter = <Modal.Footer>
        <Button onClick={closeModal} variant="secondary">Close</Button>
        <Button onClick={handleUpdate} variant="success">Save</Button>
    </Modal.Footer>;
    if (props.viewOnly) {
        viewOnly = true;
        modalFooter = <Modal.Footer><Button onClick={closeModal} variant="secondary">Close</Button></Modal.Footer>;
    }
    if (props.loadId == null) {
        modalFooter = <Modal.Footer>
            <Button onClick={closeModal} variant="secondary">Close</Button>
            <Button onClick={handleSave} variant="success">Create</Button>
        </Modal.Footer>;
    }

    let modalHeader = <Modal.Header><Modal.Title>New Load</Modal.Title></Modal.Header>;
    let modalBody = <Modal.Body><ViewLoad viewOnly={viewOnly} alerts={alerts} defaultFormValues={defaultFormValues} updateFormState={updateFormState} /></Modal.Body>;
    if (props.loadId) {
        let modalTitle = "";
        if (props.viewOnly) {
            modalTitle = "View Load";
        }
        else {
            modalTitle = "Edit Load";
        }
        if (isLoading) {
            modalHeader = <Modal.Header><Modal.Title>{modalTitle}</Modal.Title><Spinner animation="border" /></Modal.Header>;
            modalBody = <Modal.Body><ViewLoadPlaceholder count="7" /></Modal.Body>
        }
        else {
            modalHeader = <Modal.Header><Modal.Title>{modalTitle}</Modal.Title></Modal.Header>;
        }
    }

    return (
        <Modal size="lg" show={props.show} onHide={closeModal}>{modalHeader}{modalBody}{modalFooter}</Modal>
    );
}

export default LoadModal;