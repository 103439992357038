import React, { useState } from 'react';
import { Navbar, Container, Nav, Badge } from 'react-bootstrap';
import { BsBell } from "react-icons/bs";

import "../App.css";

import { useGetValidateSessionQuery } from "../features/api/apiSlice";
import { Navigate, useLocation } from 'react-router-dom';

function NotificationButton(props) {
    return <div className="notification_button">
        <Badge pill size="sm" bg="notification">{props.count}</Badge>
        <BsBell size={32} />
    </div>
}

function TopNavigationBar(props) {

    if (props.restrict === "login") {
        return (
            <Navbar bg="dark" variant="dark" expand="lg">
                <Container>
                    <Navbar.Brand href="/">Load Ape</Navbar.Brand>
                </Container>
            </Navbar>
        );
    }

    return (
        <Navbar bg="dark" variant="dark" expand="lg">
            <Container>
                <Navbar.Brand href="/">Load Ape</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/loads">Loads</Nav.Link>
                        <Nav.Link href="/expenses">Expenses</Nav.Link>
                        <Nav.Link href="/cashflow">Cashflow</Nav.Link>
                        <Nav.Link href="/users">Users</Nav.Link>
                        <Nav.Link href="/account">Account</Nav.Link>
                        <Nav.Link href="/logout">Log out</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <Nav>
                    <Nav.Link href="/user_info">{props.username}</Nav.Link>
                    <Nav.Link href="/notifications"><NotificationButton count={props.notificationCount} /></Nav.Link>
                </Nav>
            </Container>
        </Navbar>
    );
}

export function NavigationBar(prop) {
    const initialState = {loading:true, notificationCount: null, username: ""}
    const [{loading, username, notificationCount}, setState] = useState(initialState);
    const {
        data,
        isSuccess,
        isError,
    } = useGetValidateSessionQuery();

    const location = useLocation();

    if( !loading )
    {
        return <TopNavigationBar username={username} notificationCount={notificationCount} />
    }

    if( isSuccess && data["success"]) {
        const unreadNotifications = data.unread_notifications.length;
        const profileUsername = data["user_info"]["username"];
        console.log("useGetValidateSessionQuery")
        console.log(data);
        setState(prevState => ({ ...prevState, loading: false, username: profileUsername, notificationCount: unreadNotifications }));
        return <TopNavigationBar username={profileUsername} notificationCount={unreadNotifications} />;

    }
    else if( isSuccess && !data["success"] ) {
         return <Navigate to="/login" state={{ from: location, message: data["message"] }} replace />;
    }
    else if( isError ) {
        return <Navigate to="/login" state={{ from: location, message: "Error validating session."}} replace />;
    }
    else {
        return <TopNavigationBar />;
    }
    

}

export default TopNavigationBar;