import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from "../../components/Util";

// Define a service using a base URL and expected endpoints
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL, prepareHeaders: (headers, { getState }) => {
      headers.set("X-Token", localStorage.getItem("session_token"));
      return headers;
    }
  }),
  tagTypes: ['validateToken',
    'accountInfo',
    'userProfile',
    'expenseReports',
    'cashflowData',
    'comments',
    'loadHistory',
    'readNotifications',
    'notificationHistory',
    'loadDetail'],
  endpoints: (builder) => ({
    getValidateSession: builder.query({
      query: () => `auth/token/validate/`,
      providesTags: ['validateToken'],
    }),
    getExpenseReportsForAccount: builder.query({
      query: ({ offset, limit }) => `expenses/account/?offset=${offset}&limit=${limit}`,
      providesTags: ['expenseReports'],
    }),
    getAccountInfo: builder.query({
      query: () => 'account/info/',
      providesTags: ['accountInfo'],
    }),
    getUserProfile: builder.query({
      query: (username) => `users/${username}/profile/`,
      providesTags: ['userProfile'],
    }),
    getLoadDetail: builder.query({
      query: (load_id) => `loads/load/${load_id}/details/`,
      providesTags: ['loadDetail'],
    }),
    getLoadComments: builder.query({
      query: (load_id) => `loads/load/${load_id}/comments/`,
      providesTags: ['comments'],
    }),
    getCashflowData: builder.query({
      query: () => 'cashflow/',
      providesTags: ['cashflowData'],
    }),
    getNotifications: builder.query({
      query: ({ offset, limit }) => `notifications/unread/?offset=${offset}&limit=${limit}`,
      providesTags: ['readNotifications'],
    }),
    getNotificationHistory: builder.query({
      query: ({ offset, limit }) => `notifications/?offset=${offset}&limit=${limit}`,
      providesTags: ['notificationHistory'],
    }),
    getLoadHistory: builder.query({
      query: (load_id) => `loads/load/${load_id}/history/`,
      providesTags: ['loadHistory'],
    }),
    postDispatchComment: builder.mutation({
      query: ({ ...post }) => ({
        url: "loads/comments/dispatcher/add/",
        method: "POST",
        body: post,
      }),
      transformResponse: (response, meta, arg) => response.data,
      invalidatesTags: ['comments'],
    }),
    updateReadNotifications: builder.mutation({
      query: () => ({
        url: "notifications/mark_all_read/",
        method: "POST",
      }),
      transformResponse: (response, meta, arg) => response.data,
      invalidatesTags: ['readNotifications']
    }),
    updateUserProfile: builder.mutation({
      query: ({ user_id, ...put }) => ({
        url: `users/${user_id}/profile/`,
        method: 'PUT',
        body: put,
      }),
      // Pick out data and prevent nested properties in a hook or selector
      transformResponse: (response, meta, arg) => response.data,
      invalidatesTags: ['userProfile'],
    }),
    updateAccountInfo: builder.mutation({
      query: ({ ...put }) => ({
        url: "account/info/",
        method: "PUT",
        body: put,
      }),
      transformResponse: (response, meta, arg) => response.data,
      invalidatesTags: ['accountInfo'],
    }),
    updateExpenseReportStatus: builder.mutation({
      query: ({ ...put }) => ({
        url: "expenses/",
        method: "PUT",
        body: put,
      }),
      transformResponse: (response, meta, arg) => response.data,
      invalidatesTags: ['expenseReports', 'cashflowData'],
    }),
  }),
})

export const {
  useGetValidateSessionQuery,
  useGetAccountInfoQuery,
  useGetUserProfileQuery,
  useGetExpenseReportsForAccountQuery,
  useGetCashflowDataQuery,
  useGetNotificationsQuery,
  useGetLoadDetailQuery,
  useGetLoadCommentsQuery,
  useGetLoadHistoryQuery,
  useGetNotificationHistoryQuery,
  usePostDispatchCommentMutation,
  useUpdateReadNotificationsMutation,
  useUpdateUserProfileMutation,
  useUpdateAccountInfoMutation,
  useUpdateExpenseReportStatusMutation,
} = apiSlice;
