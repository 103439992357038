import React, { useState } from "react";

import { Container, Button, Badge, Card, Row, Col, Spinner, Form, Modal } from "react-bootstrap";

import TopNavigationBar from "../TopNavigationBar";
import ChangePasswordModal from "../account/ChangePasswordModal";

import { useGetValidateSessionQuery, useGetUserProfileQuery, useUpdateUserProfileMutation } from "../../features/api/apiSlice";

function UpdateFieldModal(props) {
    const [value, setValue] = useState("");
    return (
        <Modal show={props.show}>
            <Modal.Header>{props.fieldTitle}</Modal.Header>
            <Modal.Body><Form.Control defaultValue={props.defaultValue} onChange={(action) => { setValue( action.target.value ) }} /></Modal.Body>
            <Modal.Footer><Button onClick={() => { props.handleClose() }}>Cancel</Button>&nbsp;<Button onClick={() => {props.handleClose(value)}} variant="success">Update</Button></Modal.Footer>
        </Modal>
    );
}

function PrimaryUser(props) {
    const {
        data,
        isSuccess,
    } = useGetUserProfileQuery(props.username);

    const initialState = { emailAddress: "", emailAddressVerified: null, phoneNumber: "", phoneNumberVerified: null, isLoading: true };
    const [{ emailAddress, emailAddressVerified, phoneNumber, phoneNumberVerified, isLoading }, setState] = useState(initialState);

    if (isSuccess && isLoading) {
        console.log(data);
        if (data.success) {
            const profile = data.data;

            setState(prevState => ({
                ...prevState, emailAddress: profile.email_address,
                emailAddressVerified: profile.email_verified,
                phoneNumber: profile.phone_number,
                phoneNumberVerified: profile.sms_verified,
                isLoading: false
            }));
        }
    }

    if (isLoading) {
        return (
            <Container className="current_user_controller">
                <Row className="justify-content-md-center">
                    <Spinner className="mb-4" size="lg" animation="border" />
                </Row>
            </Container>
        )
    }

    let emailVerifiedBadge = "";
    if (emailAddressVerified === true) {
        emailVerifiedBadge = <Badge pill bg="success">Verified</Badge>;
    }
    else if (emailAddressVerified === false) {
        emailVerifiedBadge = <Badge pill bg="warning">Not Verified</Badge>
    }
    let phoneVerifiedBadge = "";
    if (phoneNumberVerified === true) {
        phoneVerifiedBadge = <Badge pill bg="success">Verified</Badge>;
    }
    else if (phoneNumberVerified === false) {
        phoneVerifiedBadge = <Badge pill bg="warning">Not Verified</Badge>
    }

    return (
        <Container className="current_user_controller">
            <Card style={{ maxWidth: '45rem' }} className="login_card"
                bg="light"
                text="dark">
                <Card.Header>User Info</Card.Header>
                <Card.Body>
                    <Container>
                        <Row className="mb-2">
                            <Col sm="3">Username</Col><Col sm="6">{props.username}</Col><Col sm="1"></Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm="3">E-Mail Address</Col>
                            <Col sm="6">{emailAddress}&nbsp;{emailVerifiedBadge}</Col>
                            <Col sm="1"><Button onClick={() => props.editEmailField(emailAddress)} variant="secondary" size="sm">Edit</Button></Col>
                        </Row>
                        <Row className="mb-2">
                            <Col sm="3">Mobile Number (SMS)</Col>
                            <Col sm="6">{phoneNumber}&nbsp;{phoneVerifiedBadge}</Col>
                            <Col sm="1"><Button onClick={() => props.editMobileField(phoneNumber)} variant="secondary" size="sm">Edit</Button></Col>
                        </Row>
                    </Container>
                </Card.Body>
                <Card.Footer><Button onClick={props.changePasswordModal} variant="primary">Change Password</Button></Card.Footer>
            </Card>
        </Container>
    )
}

function CurrentUserController(props) {
    const initialState = {
        defaultModalValue: "",
        displayChangeMobileModal: false,
        displayChangeEmailModal: false,
        displayChangePasswordModal: false,
        isLoading: true, username: "",
        emailAddress: null,
        phoneNumber: null
    };
    const [{ displayChangePasswordModal,
        displayChangeEmailModal,
        displayChangeMobileModal,
        defaultModalValue,
        isLoading,
        username, 
        emailAddress, 
        phoneNumber }, setState] = useState(initialState);

    const [updateUserProfile] = useUpdateUserProfileMutation();

    const {
        data,
        isSuccess,
    } = useGetValidateSessionQuery();

    let notificationCount = 0;
    if (isSuccess && data["success"] && isLoading) {
        notificationCount = data.unread_notifications.length;
        console.log("getUserProfile")
        console.log(data);
        setState(prevState => ({ ...prevState, isLoading: false, username: data["user_info"]["username"] }))
        console.log("notificationCount: " + notificationCount);
    }
    else if (isLoading) {
        return (
            <div>
                <TopNavigationBar />
                <Container>
                    <Row className="justify-content-md-center">
                        <Spinner className="mb-4" size="lg" animation="border" />
                    </Row>
                </Container>
            </div>
        );
    }

    let displayUpdateFieldModal = false;
    let updateFieldTitle = "";
    if (displayChangeEmailModal || displayChangeMobileModal) {
        displayUpdateFieldModal = true;
        if (displayChangeEmailModal) {
            updateFieldTitle = "Update e-mail address";
        }
        else if (displayChangeMobileModal) {
            updateFieldTitle = "Update mobile number";
        }
    }

    const handleEditMobileField = (phone_number) => {
        setState(prevState => ({ ...prevState, displayChangeMobileModal: true, defaultModalValue: phone_number }));
    };
    const handleEditEmailField = (email_address) => {
        setState(prevState => ({...prevState, displayChangeEmailModal: true, defaultModalValue:email_address}))
    }

    const handleClose = (new_value) => {
        console.log("handleClose()");
        if(new_value) {
            if(displayChangeEmailModal) {
                console.log("newEmail: " + new_value);
                updateUserProfile({user_id: username, email_address: new_value});
                setState(prevState => ({...prevState,emailAddress:new_value}));
            }
            else if(displayChangeMobileModal) {
                console.log("phoneNumber: " + new_value);
                updateUserProfile({user_id: username, phone_number: new_value});
                setState(prevState => ({...prevState,phoneNumber:new_value}));
            }
        }
        setState(prevState => ({ ...prevState, displayChangeEmailModal: false, displayChangeMobileModal: false }));
    }

    return <div>
        <TopNavigationBar notificationCount={notificationCount} />
        <PrimaryUser editMobileField={handleEditMobileField} 
        editEmailField={handleEditEmailField} 
        changePasswordModal={() => setState(prevState => ({ ...prevState, displayChangePasswordModal: true }))} 
        username={username}
        emailAddress={emailAddress}
        phoneNumber={phoneNumber}
         />
        <ChangePasswordModal username={username} show={displayChangePasswordModal} handleClose={() => { setState(prevState => ({ ...prevState, displayChangePasswordModal: false })) }} />
        <UpdateFieldModal defaultValue={defaultModalValue} fieldTitle={updateFieldTitle} show={displayUpdateFieldModal} handleClose={handleClose} />
    </div>
}

export default CurrentUserController;