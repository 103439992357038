import React, {useEffect, useState} from "react";
import { Container, Spinner, Row, Alert, Card, Form, Col, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { BASE_URL } from "../Util";

import TopNavigationBar from "../TopNavigationBar";

export default function ActivateAccount(props){
    const initialState = {isLoading: false, finished:false, submitting:false, new_password:"", new_password_repeat:"", resetPassword: false, message:false, username: null}

    const [{isLoading, finished, message, resetPassword, username, new_password, new_password_repeat, submitting},setState] = useState(initialState);
    const {session_id} = useParams();

    const navigate = useNavigate();

    const canActivateAccount = (session_token) => {
        const url = BASE_URL + `auth/account/activate/${session_token}/`
        setState(prevState => ({...prevState, isLoading: true}));
        try{
            fetch(url).then(response => response.json()).then(data => {
                console.log(data);
                if( data.success ) {
                    setState(prevState => ({...prevState, isLoading: true, finished:true, resetPassword:true, username: data.username}));
                }
                else {
                    setState(prevState => ({...prevState, isLoading: false, finished:true, message: data.message}));
                }
            });
        }
        catch(err) {
            console.log(err);
            setState(prevState => ({...prevState, isLoading: false}));
        }
        
    }

    

    useEffect(() => {
        if( !isLoading && !finished ) {
            console.log("canActivateAccount: " + session_id);
            canActivateAccount(session_id);
        }
    });

    const submitPasswordReset = () => {
        const url = BASE_URL + "users/activate/set_password/";
        const requestBody = { username: username, password: new_password, password_repeat: new_password };
        setState( prevState => ({...prevState, submitting: true}));
        fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'X-Token': session_id
            },
            body: JSON.stringify(requestBody)
        }).then(request => request.json()).then(data => {
            console.log(data);
            if( data.success ) {
                localStorage.setItem("session_token", data.token);
                if( "setMotorCarrier" in data && data.setMotorCarrier ) {
                    navigate("/set_motor_carrier/");
                    console.log("setMotorCarrier")
                }
                else{
                    console.log("/loads/")
                    //navigate("/loads/");
                }
            }
            else {
                setState(prevState => ({...prevState, submitting: false, message: data.message}));
            }
        });
    }

    const onFormSubmit = e => {
        e.preventDefault();
        console.log("validating form");
        if( new_password_repeat !== new_password ) {
            setState( prevState => ({...prevState, new_password: "", new_password_repeat:"", finished: true, message: "Passwords don't match."}))
        }
        else {
            console.log("POST reset password");
            submitPasswordReset();
        }
    }

    if( !finished )
    {
    return(
        <div>
            <TopNavigationBar restrict="login" />
            <Container className="login_page">
                <Row><Spinner animated="border" /></Row>
            </Container>
        </div>
    )
    }
    console.log("resetPassword: " + resetPassword)
    if( resetPassword ) {
        console.log("reset password.");
        let submitButton = <Button variant="success" type="submit">Set Password</Button>
        if( submitting ) {
            submitButton = <Button variant="success" disabled>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
            </Button>;
        }
        let alertMessage = "";
        if( message ) {
            alertMessage = <Alert>{message}</Alert>
        }
        return(
            <div>
                <TopNavigationBar restrict="login" />
                <Container className="login_page">
                        <Card style={{ maxWidth: '30rem' }} className="login_card">
                            <Form onSubmit={onFormSubmit}>
                                <Card.Header><h5>Set password for {username}</h5></Card.Header>
                                <Card.Body>
                                    
                                    {alertMessage}
                                    <Form.Group as={Row} className="mb-3" controlId="newPassword">
                                        <Form.Label column>New password</Form.Label>
                                        <Col sm="8">
                                            <Form.Control onChange={(action) => { setState(prevState => ({ ...prevState, new_password: action.target.value })) }} type="password" />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="newPasswordRepeat">
                                        <Form.Label column>Repeat new password</Form.Label>
                                        <Col sm="8">
                                            <Form.Control onChange={(action) => { setState(prevState => ({ ...prevState, new_password_repeat: action.target.value })) }} type="password" />
                                        </Col>
                                    </Form.Group>
                                    <Container>
                                    <Row className="justify-content-md-center">
                                    {submitButton}
                                    </Row>
                                    </Container>
                                </Card.Body>
                            </Form>
                        </Card>
                </Container>
            </div>
        )
    }
    else {
        let errorMessage = "Error occurred."
        if(message) {
            errorMessage = message;
        }
        return(
            <div>
                <TopNavigationBar restrict="login" />
                <Container className="login_page">
                    <Row><Alert>{errorMessage}</Alert></Row>
                </Container>
            </div>
        )

    }
    
}