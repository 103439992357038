import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";

import { NavigationBar } from "../TopNavigationBar";

import { useGetCashflowDataQuery } from "../../features/api/apiSlice";
import LoadingScreen from "../LoadingScreen";

function CashflowCard(props) {

    const formatValue = (value) => {
        if( value > 0 ) {
            return <p style={{fontWeight: "semibold", color: "green"}}>${parseFloat(value).toFixed(2)}</p>
        }
        if( value < 0 ) {
            return <p style={{fontWeight: "semibold", color: "red"}}>(${parseFloat(value).toFixed(2)})</p>
        }
        return <p style={{fontWeight: "semibold", color: "black"}}>- 0 -</p>
    }

    return(<Card>
        <Card.Header>Cashflow</Card.Header>
        <Card.Body>
            <Row>
                <Col sm>Cashflow (7 day invoiced vs. approved expenses)</Col>
                <Col sm>{formatValue(props.pastWeekInvoicesApprovedExpenses)}</Col>
                <Col sm>Cashflow (30 day invoiced vs. approved expenses)</Col>
                <Col sm>{formatValue(props.pastMonthInvoicesApprovedExpenses)}</Col>
            </Row>
            <Row>
                <Col sm>Cashflow (7 day invoiced vs. total expenses)</Col>
                <Col sm>{formatValue(props.pastWeekInvoicesTotalExpenses)}</Col>
                <Col sm>Cashflow (30 day invoiced vs. total expenses)</Col>
                <Col sm>{formatValue(props.pastMonthInvoicesTotalExpenses)}</Col>
            </Row>
        </Card.Body>
    </Card>)
}

function IncomeCard(props) {
    return (<Card>
        <Card.Header>Income</Card.Header>
        <Card.Body>
            <Row>
                <Col sm>Invoiced loads (past 7 day)</Col>
                <Col sm>${parseFloat(props.pastWeek).toFixed(2)}</Col>
                <Col sm>Invoiced loads (past 30 days)</Col>
                <Col sm>${parseFloat(props.pastMonth).toFixed(2)}</Col>
            </Row>
        </Card.Body>
    </Card>);
}

function ExpensesCard(props) {
    return (<Card>
        <Card.Header>Expenses</Card.Header>
        <Card.Body>
            <Row>
                <Col sm>Expenses (past 7 days)</Col>
                <Col sm>${parseFloat(props.pastWeek).toFixed(2)}</Col>
                <Col sm>Expenses (past 30 days)</Col>
                <Col sm>${parseFloat(props.pastMonth).toFixed(2)}</Col>
            </Row>
            <Row>
                <Col sm>Expenses (approved, past 7 days)</Col>
                <Col sm>${parseFloat(props.pastWeekApproved).toFixed(2)}</Col>
                <Col sm>Expenses (approved, past 30 days)</Col>
                <Col sm>${parseFloat(props.pastMonthExpensesApproved).toFixed(2)}</Col>
            </Row>
        </Card.Body>
    </Card>)
}

export default function CashFlow() {
    const { data, isSuccess, isFetching } = useGetCashflowDataQuery();

    if( isFetching ) {
        return <LoadingScreen />
    }

    let pastWeekCashflowInvoicesApprovedExpenses = null;
    let pastWeekCashflowInvoicesTotalExpenses = null;
    let pastMonthCashflowInvoicesTotalExpenses = null;
    let pastMonthCashflowInvoicesApprovedExpenses = null;
    let pastWeekExpenses = null;
    let pastWeekExpensesApproved = null;
    let pastMonthExpenses = null;
    let pastMonthExpensesApproved = null;
    let pastWeekInvoices = null;
    let pastMonthInvoices = null;

    if( isSuccess && data.success ) {
        const cashflow_data = data.cashflow_data;
        console.log(cashflow_data);
        if( cashflow_data.invoices_past_week.success ) {
            pastWeekInvoices = cashflow_data.invoices_past_week.invoice_total;
        }
        if( cashflow_data.invoices_past_month.success ) {
            pastMonthInvoices = cashflow_data.invoices_past_month.invoice_total;
        }
        if( cashflow_data.expenses_past_week.success ) {
            pastWeekExpensesApproved = cashflow_data.expenses_past_week.approved_sum;
            pastWeekExpenses = cashflow_data.expenses_past_week.total_sum;
            if( pastWeekInvoices !== null ) {
                pastWeekCashflowInvoicesApprovedExpenses = pastWeekInvoices - pastWeekExpensesApproved;
                pastWeekCashflowInvoicesTotalExpenses = pastWeekInvoices - pastWeekExpenses;
            }
        }
        if( cashflow_data.expenses_past_month.success ) {
            pastMonthExpensesApproved = cashflow_data.expenses_past_month.approved_sum;
            pastMonthExpenses = cashflow_data.expenses_past_month.total_sum;
            if( pastMonthInvoices !== null ) {
                pastMonthCashflowInvoicesApprovedExpenses = pastMonthInvoices - pastMonthExpensesApproved;
                pastMonthCashflowInvoicesTotalExpenses = pastMonthInvoices - pastMonthExpenses;
            }
        }
    }

    return (
        <div>
            <NavigationBar />
            <Container className="generic_controller">
                <CashflowCard pastMonthInvoicesApprovedExpenses={pastMonthCashflowInvoicesApprovedExpenses}
                    pastMonthInvoicesTotalExpenses={pastMonthCashflowInvoicesTotalExpenses}
                    pastWeekInvoicesApprovedExpenses={pastWeekCashflowInvoicesApprovedExpenses}
                    pastWeekInvoicesTotalExpenses={pastWeekCashflowInvoicesTotalExpenses} />
                <IncomeCard pastWeek={pastWeekInvoices} pastMonth={pastMonthInvoices} />
                <ExpensesCard pastWeek={pastWeekExpenses}
                    pastWeekApproved={pastWeekExpensesApproved}
                    pastMonth={pastMonthExpenses}
                    pastMonthExpensesApproved={pastMonthExpensesApproved} />
            </Container>
        </div>)
}