/* deprecated to remove */
import React, { useEffect, useState } from "react";
import { Form, Modal, Col, Row, Button, Alert, Spinner, Container } from "react-bootstrap";

import {FiCornerDownRight} from 'react-icons/fi';
import { BASE_URL } from "../Util";

function CommentAlert(props) {
    let variant="primary"
    if(props.source === "Driver")
    {
        variant = "secondary";
    }
    let timestampDate = new Date(props.timestamp);
    let datetimeString = timestampDate.toLocaleTimeString() + ", " + timestampDate.toLocaleDateString();
    return (
        <Alert variant={variant}>
            <Row>
                <Col><FiCornerDownRight />&nbsp;<strong>{props.source}</strong>&nbsp;({props.sender})</Col>
                <Col style={{'text-align':'right'}}><small>{datetimeString}</small></Col>
            </Row>
            <hr />
            <p>{props.body}</p>
        </Alert>
    )
}

function ViewComments(props) {
    const onClose = () => {
        setState( prevState => (initialState));

        props.handleClose();
    }

    const initialState = {
        comments: [],
        newMessage: "",
        isLoading: true,
        isSending: false,
        loadId: null
    };

    const [{comments, isLoading, loadId, isSending, newMessage},setState] = useState(initialState);

    const fetchData = () => {
        const session_token = localStorage.getItem("session_token");
        const url = BASE_URL + "loads/load/" + props.loadId + "/comments/";
        console.log("fetchData: " + url);
        fetch(url, {
            mode: "cors", headers: {
                "X-Token": session_token
            }
        })
            .then((resp) => resp.json())
            .then((data) => {
                console.log(data);
                if( data["success"] ) {
                    setState( prevState => ({...prevState,isLoading:false, loadId: props.loadId, comments: data["data"]}));
                }
                else {
                    setState( prevState => ({...prevState,isLoading:false, loadId: props.loadId}));
                }
            });
    }

    const postComment = (message) => {
        const session_token = localStorage.getItem("session_token");

        fetch(BASE_URL + "loads/comments/dispatcher/add/", {
            mode: "cors", headers: {
                "Content-Type": "application/json",
                "X-Token": session_token
            },
            method: "POST",
            body: JSON.stringify({ load_id: loadId, comment: message })
        }).then((resp) => resp.json())
            .then((data) => {
                console.log(data);
                props.triggerRefresh();
                setState(prevState => ({ ...prevState, isSending: false, isLoading: true }))
            }
        );
    }

    const onChangeMessage = (action) => {
        setState( prevState => ({...prevState, newMessage:action.target.value}));
    }

    const onSendMessage = () => {
        console.log("sendMessage: " + newMessage);
        postComment(newMessage);
        setState( prevState => ({...prevState, newMessage: "", isSending: true}));
    }

    useEffect( () => {
        if(props.show && isLoading) {
            fetchData();
        }
    })
    let modalBody = <Row className="justify-content-md-center"><Spinner className="mb-4" size="lg" animation="border" /></Row>
    if(!isLoading) {
        if( comments.length === 0 ) {
            modalBody = <div className="no_comments">No comments.</div>
        }
        else {
            modalBody = [];
            for( let eachComment of comments ) {
                modalBody.push(<CommentAlert source={eachComment["CommentSource"]}
                    sender={eachComment["Sender"]}
                    timestamp={eachComment["CreatedTimestamp"]}
                    body={eachComment["CommentBody"]} />);
            }
        }
    }
    let sendButton = <Button onClick={onSendMessage}>Send</Button>;
    if( isSending ) {
        sendButton = <Button disabled><Spinner as="span" role="status" size="sm" animation="border" /></Button>
    }
    return (<Modal size="lg" show={props.show} onHide={onClose}>
        <Modal.Header closeButton>Load Comments (#{props.loadNumber})</Modal.Header>
        <Modal.Body>
            {modalBody}
            <Container>
                <Row>
                    <Col>
                        <Form.Control id="message" onChange={onChangeMessage} value={newMessage} placeholder="New message" />
                    </Col>
                    <Col xs="auto">
                        {sendButton}
                    </Col>
                </Row>
            </Container>
        </Modal.Body>
    </Modal>)
}

export default ViewComments;