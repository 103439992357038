import { Pagination } from "react-bootstrap";

export default function PaginationComponent(props) {
    if( props.currentPage > 0 && props.totalPages > 0 ) {
        let pageItems = [];
        for( let x = 1; x <= props.totalPages; x += 1 ) {
            pageItems.push(<Pagination.Item onClick={() => props.setCurrentPage(x)} key={x} active={x === props.currentPage}>{x}</Pagination.Item>)
        }
        return (<Pagination>{pageItems}</Pagination>);
    }
    else {
        return <div />;
    }
}