import React from 'react';
import LoadsController from './components/loads/LoadsController';
import LoginController from './components/login/LoginController';
import UserMgmtController from './components/users/UserMgmtController';
import AccountController from './components/account/AccountController';
import NotificationsController, { NotificationHistoryController } from './components/account/NotificationsController';

import { useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from 'react-router-dom';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import ValidationController from './components/login/ValidationController';
import LogoutController from './components/login/LogoutController';
import ActivateAccount from './components/account/ActivateAccount';
import ForgotPassword from './components/login/ForgotPassword';
import CreateNewAccount from './components/account/CreateNewAccount';
import SetMotorCarrier from './components/account/SetMotorCarrier';
import CurrentUserController from './components/users/CurrentUserController';
import ExpensesController from './components/expenses/ExpensesController';
import CashFlow from './components/cashflow/CashFlow';
import LoadDetail from './components/loads/LoadDetail';

function RequireAuth( {children} ) {
  let auth = false;
  let location = useLocation();
  let token = useSelector((state) => state.user.token);
  if( !token ) {
    token = localStorage.getItem("session_token");
  }
  if(token) {
    auth = true;
  }

  if( !auth ) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function App() {
  return (
    <div className="App">
        <div className="main_controller">
            <BrowserRouter>
                <Routes>
                    <Route path="/loads" element={<RequireAuth><LoadsController /></RequireAuth>} />
                    <Route path="/users" element={<RequireAuth><UserMgmtController /></RequireAuth>} />
                    <Route path="/user_info" element={<RequireAuth><CurrentUserController /></RequireAuth>} />
                    <Route path="/create_account" element={<CreateNewAccount />} />
                    <Route path="/cashflow" element={<RequireAuth><CashFlow /></RequireAuth>}/>
                    <Route path="/set_motor_carrier" element={<RequireAuth><SetMotorCarrier /></RequireAuth>}/>
                    <Route path="/forgot_password" element={<ForgotPassword />} />
                    <Route path="/load_details/:load_id" element={<RequireAuth><LoadDetail /></RequireAuth>} />
                    <Route path="/validate/:session_id" element={<ValidationController />} />
                    <Route path="/activate/:session_id" element={<ActivateAccount />} />
                    <Route path="/notifications" element={<RequireAuth><NotificationsController /></RequireAuth>} />
                    <Route path="/notifications/history" element={<RequireAuth><NotificationHistoryController /></RequireAuth>} />
                    <Route path="/expenses/:page" element={<RequireAuth><ExpensesController /></RequireAuth>} />
                    <Route path="/expenses/" element={<RequireAuth><ExpensesController /></RequireAuth>} />
                    <Route path="/login" element={<LoginController />} />
                    <Route path="/logout" element={<LogoutController />} />
                    <Route path="/account" element={<RequireAuth><AccountController /></RequireAuth>} />
                    <Route path="*" element={<RequireAuth><LoadsController /></RequireAuth>} />
                </Routes>
            </BrowserRouter>
        </div>
    </div>
  );
}

export default App;
