import React, {Component} from 'react';
import { Container, Row, Col, Modal, Button, ListGroup } from 'react-bootstrap';
import TopNavigationBar from '../TopNavigationBar';

import { Navigate } from 'react-router';

import LoadsTable from './LoadsTable';
import LoadModal from './LoadModal';
import ViewComments from './ViewComments';
import { BASE_URL } from '../Util';

function PaidLoadAlert(props) {
    return (<Modal show={props.show} onHide={props.handleCloseModal}>
        <Modal.Header>Mark load paid</Modal.Header>
        <Modal.Body>Do you want to mark the load as paid in full?</Modal.Body>
        <Modal.Footer>
            <Button className="btn-secondary" onClick={props.handleCloseModal} variant="secondary">Cancel</Button>
            <Button className="btn-success" onClick={props.onConfirmPaid} variant="success">Confirm</Button>
        </Modal.Footer>
    </Modal>);
}

function InvoiceLoadAlert(props) {
    return (<Modal show={props.show} onHide={props.handleCloseModal}>
        <Modal.Header>Invoice load</Modal.Header>
        <Modal.Body>Are you sure you want to invoice the load? The load will no longer be able to be updated by the driver app after being invoiced.</Modal.Body>
        <Modal.Footer>
            <Button className="btn-secondary" onClick={props.handleCloseModal} variant="secondary">Cancel</Button>
            <Button className="btn-success" onClick={props.onConfirmInvoice} variant="success">Invoice</Button>
        </Modal.Footer>
    </Modal>);
}


function CancelLoadAlert(props) {
    return (<Modal show={props.show} onHide={props.handleCloseModal}>
        <Modal.Header>Cancel Load</Modal.Header>
        <Modal.Body>Are you sure you want to cancel the load?</Modal.Body>
        <Modal.Footer>
            <Button className="btn-secondary" onClick={props.handleCloseModal} variant="secondary">No</Button>
            <Button className="btn-danger" onClick={props.onConfirmCancel} variant="danger">Yes</Button>
        </Modal.Footer>
    </Modal>);
}

function RemoveAssignedLoadAlert(props) {
    return (<Modal show={props.show} onHide={props.handleCloseModal}>
        <Modal.Header>Remove Assigned Driver</Modal.Header>
        <Modal.Body>Are you sure you want to remove assigned driver from the load?</Modal.Body>
        <Modal.Footer>
            <Button className="btn-secondary" onClick={props.handleCloseModal} variant="secondary">No</Button>
            <Button className="btn-danger" onClick={props.onConfirmRemoveDriver} variant="danger">Yes</Button>
        </Modal.Footer>
    </Modal>);
}

function LoadSubmenu(props) {
    let menuItems = [{ label: "Unassigned", key: "unassigned" }, { label: "Assigned", key: "assigned" },{ label: "Active", key:"active"},
    { label: "Completed", key: "completed" },
    { label: "Cancelled", key: "cancelled" },
    { label: "Invoiced", key: "invoiced" },
    { label: "Paid", key: "paid"}];
    let menuTags = [];
    for (let eachItem of menuItems) {
        menuTags.push(<ListGroup.Item key={eachItem.key} eventKey={eachItem.key} onClick={() => props.handleSelect(eachItem.key)}>{eachItem.label}</ListGroup.Item>)
    }
    return (
        <Container>
            <Row>
                <Col><Button className='mb-2' variant="success" onClick={props.handleNewLoad}>New Load</Button></Col><Col></Col>
            </Row>
            <Row>
                <Col>
                <ListGroup className='mb-2' horizontal="md" defaultActiveKey={props.selectedMenuItem}>
                    {menuTags}
                </ListGroup>
                </Col>
            </Row>
        </Container>);
}

export default class LoadsController extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadsData: [],
            editLoadModalVisible: false,
            cancelLoadModalVisible: false,
            cancelLoadId:null,
            removeAssignLoadModalVisible:false,
            removeAssignLoadId: null,
            invoiceLoadModalVisible: false,
            invoiceLoadId: null,
            paidLoadModalVisible: false,
            paidLoadId: null,
            editLoadId:null,
            editLoadViewOnly: false,
            selectedMenuItem:"unassigned",
            loadingFromBackend: false,
            commentsModalVisible: false,
            viewCommentsLoadId: null,
            viewCommentsLoadNumber: null,
            notificationCount:0,
            userInfo:null,
            invalidSession: false,
        }
        this.newLoad = this.newLoad.bind(this);
        this.handleCloseCancelModal = this.handleCloseCancelModal.bind(this);
        this.handleCancelLoad = this.handleCancelLoad.bind(this);
        this.handleSelectMenu = this.handleSelectMenu.bind(this);
        this.handleViewLoad = this.handleViewLoad.bind(this);
        this.handleEditLoad = this.handleEditLoad.bind(this);
        this.handleCloseEditModal = this.handleCloseEditModal.bind(this);
        this.handleConfirmCancelLoad = this.handleConfirmCancelLoad.bind(this);
        this.handleAssignDriverForLoad = this.handleAssignDriverForLoad.bind(this);
        this.handleRemoveAssignment = this.handleRemoveAssignment.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleConfirmRemoveAssignedDriver = this.handleConfirmRemoveAssignedDriver.bind(this);
        this.handleCloseCommentsModal = this.handleCloseCommentsModal.bind(this);
        this.handleConfirmInvoiceLoad = this.handleConfirmInvoiceLoad.bind(this);
        this.handleInvoiceLoad = this.handleInvoiceLoad.bind(this);
        this.handleViewComments = this.handleViewComments.bind(this);
        this.refreshTable = this.refreshTable.bind(this);
        this.validateSession = this.validateSession.bind(this);
        this.handleNewLoad = this.handleNewLoad.bind(this);
        this.handlePaidLoad = this.handlePaidLoad.bind(this);
        this.handleConfirmPaidLoad = this.handleConfirmPaidLoad.bind(this);
    }

    handleNewLoad() {
        console.log("handleNewLoad");
        this.refreshTable();
    }


    handleConfirmPaidLoad () {
        this.setState({paidLoadModalVisible:false});
        const session_token = localStorage.getItem("session_token");
        fetch(BASE_URL + `loads/set_paid/${this.state.paidLoadId}/`, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'X-Token': session_token
            },
        }).then(response => response.json())
        .then(data => {
            console.log(data);
            if(data.success) {
                this.loadDataFromBackend("invoiced");
            }
        });
    }

    handleConfirmInvoiceLoad () {
        this.setState({invoiceLoadModalVisible:false});
        const session_token = localStorage.getItem("session_token");
        fetch(BASE_URL + `loads/set_invoiced/${this.state.invoiceLoadId}/`, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'X-Token': session_token
            },
        }).then(response => response.json())
        .then(data => {
            console.log(data);
            if(data.success) {
                this.loadDataFromBackend("completed");
            }
        });
    }

    handleCloseCommentsModal() {
        this.setState({commentsModalVisible:false, viewCommentsLoadId: null, viewCommentsLoadNumber: null});
    }

    handleSelectMenu(key) {
        this.setState({selectedMenuItem:key});
        this.loadDataFromBackend(key);
    }

    componentDidMount() {
        this.validateSession();
        this.loadDataFromBackend();
    }

    refreshTable() {
        this.loadDataFromBackend(this.state.selectedMenuItem);
    }

    validateSession() {
        const session_token = localStorage.getItem("session_token");
        fetch(BASE_URL + "auth/token/validate/", {
            method:"GET",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'X-Token': session_token
            }}).then(response => response.json())
            .then(data => {
                console.log(data);
                if( data.success ) {
                    this.setState({notificationCount:data.unread_notifications.length, userInfo: data.user_info});
                }
                else {
                    this.setState({invalidSession: true});
                }
            });
    }
    
    loadDataFromBackend(key)
    {
        const session_token = localStorage.getItem("session_token");
        let url = "loads/unassigned-loads/"
        if( key === "cancelled") {
            url = "loads/cancelled/";
        }
        else if( key === "assigned" ) {
            url = "loads/assigned/";
        }
        else if( key === "completed") {
            url = "loads/completed/";
        }
        else if( key === "active") {
            url = "loads/active/";
        }
        else if( key === "invoiced") {
            url = "loads/invoiced/";
        }
        else if( key === "paid") {
            url = "loads/paid/";
        }
        const fetchData = async() => {
            try {
                fetch(BASE_URL +url, {
                    headers:{
                        "X-Token": session_token
                    }
                }).then( response => response.json()).then( data => {
                    if( data.success ) {
                        this.setState({loadsData: data.data, loadingFromBackend: false})
                    }
                });                
            }
            catch(error) {
                console.error(error);
                this.setState({loadingFromBackend:false});
            }
        };
        this.setState({loadingFromBackend:true});
        fetchData();
    }

    newLoad() {
        console.log("New load");
        this.setState({editLoadModalVisible:true,
        editLoadId:null});
    }

    handleCloseModal() {
        console.log("handleCloseModal");
        this.setState({
            removeAssignLoadModalVisible: false,
            editLoadModalVisible: false,
            cancelLoadModalVisible: false,
            invoiceLoadModalVisible: false,
            paidLoadModalVisible: false
        });
    }

    handleCloseEditModal() {
        this.setState({editLoadModalVisible:false});
    }

    handleCloseCancelModal() {
        this.setState({cancelLoadModalVisible:false});
    }

    handleConfirmRemoveAssignedDriver() {
        console.log("handleConfirmRemoveAssignedDriver");
        this.setState({removeAssignLoadModalVisible:false});
        const session_token = localStorage.getItem("session_token");
        fetch(BASE_URL + `loads/load/${this.state.removeAssignLoadId}/remove_driver/`, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'X-Token': session_token
            },
        }).then(response => response.json())
        .then(data => {
            console.log(data);
            if(data.success) {
                this.loadDataFromBackend("assigned");
            }
        });
    }

    handleConfirmCancelLoad() {
        this.setState({cancelLoadModalVisible:false});
        const request_body = {"load_id": this.state.cancelLoadId, "action": "cancel"};
        const session_token = localStorage.getItem("session_token");
        console.log(JSON.stringify(request_body));
        fetch(BASE_URL + "loads/load/cancel/", {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'X-Token': session_token
            },
            body:JSON.stringify(request_body)
        }).then(response => response.json())
        .then(data => {
            console.log(data);
            if(data.success) {
                this.loadDataFromBackend();
            }
        });
    }

    handleAssignDriverForLoad(driver, load_id) {
        console.log("assignDriver: " + driver + " for load " + load_id);
        const request_body = {"load_id": load_id, "driver": driver};
        const session_token = localStorage.getItem("session_token");
        fetch(BASE_URL + "loads/load/assign/", {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'X-Token': session_token
            },
            body:JSON.stringify(request_body)
        }).then(response => response.json())
        .then(data => {
            console.log(data);
            if(data.success) {
                this.loadDataFromBackend();
            }
        });

    }

    handlePaidLoad(load_id) {
        this.setState({paidLoadId: load_id, paidLoadModalVisible: true});
    }

    handleInvoiceLoad(load_id) {
        this.setState({invoiceLoadId: load_id, 
            invoiceLoadModalVisible: true});
    }

    handleViewLoad(load_id) {
        console.log("handleViewLoad: " + load_id);
        this.setState({editLoadId:load_id,
        editLoadModalVisible:true,
        editLoadViewOnly:true});
    }

    handleCancelLoad(load_id) {
        console.log("handleCancelLoad: " + load_id);
        this.setState({cancelLoadId:load_id,
        cancelLoadModalVisible:true});
    }

    handleRemoveAssignment(load_id) {
        console.log("handleRemoveAssignment: " + load_id);
        this.setState({removeAssignLoadId:load_id,
        removeAssignLoadModalVisible:true});
    }

    handleEditLoad(load_id) {
        console.log("handleEditLoad: " + load_id);
        this.setState({editLoadId:load_id,
        editLoadModalVisible:true,
        editLoadViewOnly:false});
    }

    handleViewComments(load_id, load_number) {
        console.log("handleViewComments: " + load_id + "(load #" + load_number + ")");
        this.setState({commentsModalVisible:true, viewCommentsLoadNumber: load_number, viewCommentsLoadId: load_id});
    }

    render() {
        console.log("render(): loadingFromBackend: " + this.state.loadingFromBackend);
        let topNavigationBar = <TopNavigationBar />;
        if( this.state.notificationCount > 0 ) {
            console.log(this.state.userInfo);
            topNavigationBar = <TopNavigationBar username={this.state.userInfo.username} notificationCount={this.state.notificationCount} />
        }
        else if( this.state.userInfo ) {
            topNavigationBar = <TopNavigationBar username={this.state.userInfo.username} />
        }

        if( this.state.invalidSession ) {
            return (<Navigate to="/login" state={{message:"Session expired."}} />)
        }

        return (
            <div>
                {topNavigationBar}
                <Container className="loads_controller">
                    <Row>
                            <LoadSubmenu selectedMenuItem={this.state.selectedMenuItem}
                                handleSelect={this.handleSelectMenu}
                                handleNewLoad={this.newLoad} />
                        </Row>
                        <Row>
                            <LoadsTable loading={this.state.loadingFromBackend}
                                selectedMenuItem={this.state.selectedMenuItem}
                                onCancelLoad={this.handleCancelLoad}
                                onEditLoad={this.handleEditLoad}
                                onViewLoad={this.handleViewLoad}
                                onRemoveAssignedDriver={this.handleRemoveAssignment}
                                onAssignLoad={this.handleAssignDriverForLoad}
                                onViewComments={this.handleViewComments}
                                onInvoiceLoad={this.handleInvoiceLoad}
                                onPaidLoad={this.handlePaidLoad}
                                loadsData={this.state.loadsData} />
                    </Row>
                </Container>
                <ViewComments loadNumber={this.state.viewCommentsLoadNumber} 
                loadId={this.state.viewCommentsLoadId} 
                show={this.state.commentsModalVisible} 
                handleClose={this.handleCloseCommentsModal}
                triggerRefresh = {this.refreshTable} />
                <LoadModal key={this.state.editLoadId}
                    loadId={this.state.editLoadId}
                    defaultFormValues={null}
                    viewOnly={this.state.editLoadViewOnly}
                    handleNewLoad={this.handleNewLoad}
                    handleCloseModal={this.handleCloseEditModal}
                    show={this.state.editLoadModalVisible} />
                <InvoiceLoadAlert show={this.state.invoiceLoadModalVisible}
                    onConfirmInvoice={this.handleConfirmInvoiceLoad}
                    handleCloseModal={this.handleCloseModal} />
                <CancelLoadAlert show={this.state.cancelLoadModalVisible}
                    onConfirmCancel={this.handleConfirmCancelLoad}
                    handleCloseModal={this.handleCloseCancelModal} />
                <RemoveAssignedLoadAlert show={this.state.removeAssignLoadModalVisible}
                    onConfirmRemoveDriver={this.handleConfirmRemoveAssignedDriver}
                    handleCloseModal={this.handleCloseModal} />
                <PaidLoadAlert show={this.state.paidLoadModalVisible}
                    onConfirmPaid={this.handleConfirmPaidLoad}
                    handleCloseModal={this.handleCloseModal} />
            </div>
        );
    }
}
