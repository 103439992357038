import React, { useState } from "react";
import TopNavigationBar from "../TopNavigationBar";
import { Form, Button, Container, Row, Col, Card, Alert, Spinner } from "react-bootstrap";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../Util";
import { useDispatch } from "react-redux";

import { setToken } from "../../features/user/userSlice"

function LoginController(props) {
    const initialState = { username: "", password: "", isLoading: false, message: null, redirect: null }
    const [{ username, password, isLoading, message, redirect }, setState] = useState(initialState);
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    if (redirect) {
        return <Navigate to={redirect} state={{ from: location }} replace />;
    }

    if(location.state && location.state.message !== null && location.state.message !== undefined) {
        if( location.state.message !== message ) {
            setState(prevState=> ({...prevState,message: location.state.message}));
        }
    }

    const onChange = event => {
        if (event.target.id === "loginUsername") {
            setState(prevState => ({ ...prevState, username: event.target.value }));
        }
        else if (event.target.id === "loginPassword") {
            setState(prevState => ({ ...prevState, password: event.target.value }))
        }
    }

    const onFormSubmit = e => {
        e.preventDefault();

        let request_body = { username: username, password: password };
        setState(prevState => ({...prevState, isLoading: true}));
        fetch(BASE_URL + "auth/login/", {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request_body)
        }).then(response => response.json())
            .then(data => {
                console.log(data);
                if (data.success) {
                    const roles = data.user_info.roles;
                    let authenticated = false;
                    if( roles.includes("Dispatcher") ) {
                        console.log("has Dispatcher role");
                        authenticated = true;
                    }
                    if( roles.includes("Owner") ) { 
                        console.log("has Owner role");
                        authenticated = true;
                    }
                    if( authenticated ) {
                        localStorage.setItem("session_token", data.token);
                        dispatch(setToken(data.token));
                        setState(prevState => ({ ...prevState, redirect: "/loads", isLoading:false }));
                    }
                    else {
                        setState(prevState => ({...prevState, message: "User does not have Dispatcher permission (use Driver app)", isLoading: false}));
                    }
                    
                }
                else {
                    setState(prevState => ({...prevState, message: "Invalid username/password", isLoading: false}));
                }
            }).catch(function(error) {
                setState( prevState => ({...prevState, message: "Can't connect to backend.", isLoading: false}));
            });
    }

    let alertMessage = "";
    console.log("message: " + message);
    if( message != null ) {
        alertMessage = <Alert variant="info">{message}</Alert>
    }

    let signInButton = <Button variant="success" type="submit" className="mb-3">Sign in</Button>;
    if( isLoading ) {
        signInButton = <Button variant="success" disabled>
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />
    </Button>
    }

    return (
        <div>
            <TopNavigationBar restrict="login" />
            <Container className="login_page">
                <Card style={{ maxWidth: '30rem' }} className="login_card">
                    <Form onSubmit={onFormSubmit}>
                    <Card.Header><h3>Dispatch</h3></Card.Header>
                        <Card.Body>
                            {alertMessage}
                            <Form.Group className="mb-2" controlId="loginUsername">
                                <Form.Label className="mb-2">Username</Form.Label>
                                <Form.Control onChange={onChange} value={username} placeholder="Username" className="mb-3"></Form.Control>
                            </Form.Group>
                            <Form.Group controlId="loginPassword" className="mb-3">
                                <Form.Label className="mb-2">Password</Form.Label>
                                <Form.Control onChange={onChange} value={password} className="mb-2" type="password" placeholder="Password"></Form.Control>
                            </Form.Group>
                        </Card.Body>
                        <Card.Footer>
                            <Container>
                                <Row>
                                    <Col sm="3">{signInButton}</Col>
                                    <Col sm="auto"><Button variant="secondary" onClick={() => navigate("/forgot_password")} type="button" className="mb-3">Forgot password</Button></Col>
                                    <Col sm="4"><Button variant="secondary" onClick={() => navigate("/create_account")} type="button" className="mb-3">New account</Button></Col>
                                </Row>
                            </Container>
                        </Card.Footer>
                    </Form>
                </Card>
            </Container>
        </div>
    );
}

export default LoginController;