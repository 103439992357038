import React, { useState } from "react";

import { Container, Form, Button, Card, CardGroup, Modal } from "react-bootstrap";

import { NavigationBar } from "../TopNavigationBar";

import { useGetAccountInfoQuery, useUpdateAccountInfoMutation } from "../../features/api/apiSlice";
import LoadingScreen from "../LoadingScreen";

function ProvisionedUsersModal(props) {
    const [value, setValue] = useState(props.provisionedUsers)
    const [updateAccountInfo] = useUpdateAccountInfoMutation();

    const updateProvisionedUsers = () => {
        const put = { account_id: props.accountId, provisioned_users: value };
        console.log(JSON.stringify(put));
        updateAccountInfo(put);
        props.handleClose(true);
    }

    const closeModal = () => {
        props.handleClose();
    }

    return (<Modal show={props.show}>
        <Modal.Header>Provisioned users</Modal.Header>
        <Modal.Body>
            <Form.Control defaultValue={value} onChange={(action) => setValue(action.target.value)} />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>Dismiss</Button>&nbsp;
            <Button onClick={updateProvisionedUsers} variant="success">Save</Button>
        </Modal.Footer>
    </Modal>);
}

function CurrentBilling(props) {

    const formatExpirationDate = (date) => new Date(date).toLocaleDateString('en-us', { month: 'long', day: "numeric" });

    const formatProvisionedUsers = users => {
        if (users === 1) {
            return "1 user";
        }
        else {
            return `${users} users`;
        }
    }

    return (
        <Container>
            <CardGroup>
                <Card className="mb-2 login_card" style={{ maxWidth: '45rem' }}>
                    <Card.Header><span className="text-muted">Current monthly bill</span></Card.Header>
                    <Card.Body><h4>$0</h4></Card.Body>
                    <Card.Footer>{formatProvisionedUsers(props.provisionedUsers)}&nbsp;<Button onClick={() => props.showProvisionedModal()} variant="primary" disabled>Change</Button>
                    </Card.Footer>
                </Card>
                <Card className="mb-2 login_card" style={{ maxWidth: '45rem' }}>
                    <Card.Header><span className="text-muted">Rebill date</span></Card.Header>
                    <Card.Body><h4>{formatExpirationDate(props.expiration)}</h4></Card.Body>
                    <Card.Footer><Button variant="danger" disabled>Unsubscribe</Button>
                    </Card.Footer>
                </Card>
                <Card className="mb-2 login_card" style={{ maxWidth: '45rem' }}>
                    <Card.Header><span className="text-muted">PayPal account</span></Card.Header>
                    <Card.Body></Card.Body>
                    <Card.Footer><Button variant="primary" disabled>Update</Button>
                    </Card.Footer>
                </Card>
            </CardGroup>
        </Container>
    )
}

function CompanyName(props) {
    return (<h2>{props.companyName}</h2>)
}


function AccountController(props) {
    const initialState = {
        loading: true,
        accountId: "",
        currentProvisionedUsers: 0,
        displayProvisionedUsersModal: false,
        expiration: "",
        companyName: ""
    };

    const {
        data,
        isSuccess,
        isFetching,
        refetch,
    } = useGetAccountInfoQuery();

    const handleClose = (forceReload) => {
        if (forceReload) {
            console.log("forceReload");
            setState(prevState => ({ ...prevState, loading: true, displayProvisionedUsersModal: false }));
            refetch();
        }
        else {
            setState(prevState => ({ ...prevState, displayProvisionedUsersModal: false }));
        }
    }

    const [{
        accountId,
        displayProvisionedUsersModal,
        currentProvisionedUsers,
        companyName,
        expiration }, setState] = useState(initialState);

    if (isFetching) {
        return (
            <LoadingScreen />
        );
    }

    if (isSuccess && data["success"] && accountId === "") {
        const profile_info = data;
        setState(prevState => ({
            ...prevState,
            expiration: profile_info.expiration,
            currentProvisionedUsers: profile_info.provisioned_users,
            accountId: profile_info.account_id,
            companyName: profile_info.company_name
        }));
    }

    return (
        <div>
            <NavigationBar />
            <Container className="generic_controller">
                <CompanyName companyName={companyName} />
                <CurrentBilling provisionedUsers={currentProvisionedUsers}
                    expiration={expiration}
                    showProvisionedModal={() => setState(prevState => ({ ...prevState, displayProvisionedUsersModal: true, currentProvisionedUsers: currentProvisionedUsers }))}
                />
                <ProvisionedUsersModal show={displayProvisionedUsersModal}
                    provisionedUsers={currentProvisionedUsers}
                    accountId={accountId}
                    handleClose={handleClose}
                />
            </Container>
        </div>
    )
}

export default AccountController;