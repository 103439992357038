import { NavigationBar } from "./TopNavigationBar";
import { Container, Spinner, Row } from "react-bootstrap";

export default function LoadingScreen() {
    return (<div>
        <NavigationBar />
        <Container className="generic_controller">
            <Row className="justify-content-md-center"><Spinner animation="border" /></Row>
        </Container>
    </div>);
}