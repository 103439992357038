import React, { useState } from "react";
import { Modal, Button, Form, Row, Col, Alert, Spinner } from "react-bootstrap";
import { BASE_URL } from "../Util";

export default function InviteUserModal(props) {
    const initialState = {username: "", 
    phoneNumber: "",
     emailAddress: "", 
     firstName: "", 
     lastName: "", 
     dispatcher: false, 
     driver: true,
     isSending: false,
     alerts: []}

    const [{username, phoneNumber, firstName, lastName, emailAddress, dispatcher, driver, alerts, isSending}, setState] = useState(initialState);

    const onClose = () => {
        setState(initialState);
        props.handleClose();
    }

    const postData = () => {
        const session_token = localStorage.getItem("session_token");
        setState(prevState => ({ ...prevState, isSending: true}))

        let roles = [];
        if(driver) {
            roles.push("Driver");
        }
        if(dispatcher) {
            roles.push("Dispatcher");
        }

        fetch(BASE_URL + "users/invite/", {
            mode: "cors", headers: {
                "Content-Type": "application/json",
                "X-Token": session_token
            },
            method: "POST",
            body: JSON.stringify({
                first_name: firstName,
                last_name: lastName,
                username: username,
                phone_number: phoneNumber,
                email_address: emailAddress,
                roles: roles
            })
        }).then((resp) => resp.json())
            .then((data) => {
                console.log(data);
                if( data["success"] ) {
                    onClose();
                }
            }
        );
    }

    const validate = () => {
        const phone_number_re1 = /^\(\d{3}\)\d{3}-\d{4}$/;
        const phone_number_re2 = /^(?:\d{3}|\(\d{3}\))([-/.])\d{3}\1\d{4}$/;
        const phone_number_re3 = /^\d{10}/;

        let phoneNumberValid = false;
        let newAlerts = [];
        if( phoneNumber !== "" ) {
            phoneNumberValid = phone_number_re1.exec(phoneNumber);
            if(!phoneNumberValid) {
                phoneNumberValid = phone_number_re2.exec(phoneNumber);
            }
            if(!phoneNumberValid) {
                phoneNumberValid = phone_number_re3.exec(phoneNumber);
            }
            if(!phoneNumberValid){
                newAlerts.push("Invalid phone number. Must enter in a valid 10 digit format with area code.");   
            }
        }

        const email_re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if( emailAddress !== "") {
            const emailAddressValid = email_re.exec(emailAddress.toLowerCase());
            if(!emailAddressValid ) {
                newAlerts.push("Must enter a valid e-mail address.")
            }
        }

        const user_regex = /^[A-Za-z]{1,16}[A-Za-z0-9_]{5,16}$/;

        const usernameValid = user_regex.exec(username.toLowerCase());
        if( !usernameValid ) {
            newAlerts.push("Invalid username. Usernames must contain 5-16 characters, start with a letter and may include letters, digits, and underscores.");
        }
        

        if( !driver && !dispatcher ) {
            newAlerts.push( "A user must have either a driver role, dispatch role, or both roles.");
        }
        if( phoneNumber === "" && emailAddress === "") {
            newAlerts.push("You must provide an e-mail address, phone number (SMS), or both.");
        }
        if( newAlerts.length === 0 ) {
            postData();
        }
        setState( prevState => ({...prevState, alerts: newAlerts}));
    }

    let alertMessages = [];

    for( let each of alerts ) {
        alertMessages.push(<Alert variant="danger">{each}</Alert>);
    }

    let inviteButton = <Button onClick={validate} variant="success">Invite</Button>;
    if( isSending ) {
        inviteButton = <Button variant="success" disabled><Spinner size="sm" animation="border" /></Button>
    }

    return (<Modal show={props.show} onHide={onClose}>
        <Modal.Header>Invite new user</Modal.Header>
        <Modal.Body>
            {alertMessages}
            <Form.Group as={Row} className="mb-3" controlId="firstname">
                <Form.Label column>First Name</Form.Label>
                <Col sm="8">
                    <Form.Control onChange={(action) => {setState( prevState => ({...prevState, firstName: action.target.value}))}} value={firstName} placeholder="required" />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="lastname">
                <Form.Label column>Last Name</Form.Label>
                <Col sm="8">
                    <Form.Control onChange={(action) => {setState( prevState => ({...prevState, lastName: action.target.value}))}} value={lastName} placeholder="required" />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="username">
                <Form.Label column>Username</Form.Label>
                <Col sm="8">
                    <Form.Control onChange={(action) => {setState( prevState => ({...prevState, username: action.target.value}))}} value={username} placeholder="required" />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="phoneNumber">
                <Form.Label column>Mobile Number (SMS)</Form.Label>
                <Col sm="6">
                    <Form.Control onChange={(action) => {setState( prevState => ({...prevState, phoneNumber: action.target.value}))}} value={phoneNumber} placeholder="optional" />
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="emailAddress">
                <Form.Label column>E-mail Address</Form.Label>
                <Col sm="8">
                    <Form.Control onChange={(action) => {setState( prevState => ({...prevState, emailAddress: action.target.value}))}} value={emailAddress} placeholder="optional" />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="roles">
                <Form.Label column>Roles</Form.Label>
                <Col sm="8">
                    <Form.Check onChange={(action) => { setState( prevState => ({...prevState, driver: action.target.checked}))}} checked={driver} type="checkbox" label="Driver" />
                    <Form.Check onChange={(action) => { setState( prevState => ({...prevState, dispatcher: action.target.checked}))}} checked={dispatcher} type="checkbox" label="Dispatcher" />
                </Col>
            </Form.Group>
        </Modal.Body>
        <Modal.Footer><Button onClick={onClose} variant="secondary">Close</Button>&nbsp;{inviteButton}</Modal.Footer>
    </Modal>)
}