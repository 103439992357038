import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Spinner, Modal } from 'react-bootstrap';
import { NavigationBar } from '../TopNavigationBar';
import { BASE_URL } from '../Util';
import InviteUserModal from './InviteUserModal';
import UsersTable from './UsersTable';

function RemoveUserModal( props ) {
    return (
        <Modal show={props.show}>
            <Modal.Header>Remove user?</Modal.Header>
            <Modal.Body>Are you sure you want to remove {props.username} from your account?</Modal.Body>
            <Modal.Footer><Button onClick={props.handleCancel} variant="secondary">Cancel</Button>&nbsp;<Button onClick={()=>{props.confirmRemoveUser(props.username)}} variant="danger">Remove</Button></Modal.Footer>
        </Modal>
    )
}

function AlertModal( props ) {
    return (
    <Modal show={props.show}>
        <Modal.Body>{props.message}</Modal.Body>
        <Modal.Footer><Button onClick={props.handleCancel} variant="secondary">Close</Button></Modal.Footer>
    </Modal>
    );
}

function UserMgmtController(props) {
    const initialState = {isLoading: true, 
        users:[],
        provisionedUsers: null,
        inactiveUsers: null,
        userCount: 0,
        displayInviteUserModal: false,
        displayRemoveUserModal: false,
        removeUsername: null,
        alertMessage: null,
        displayAlertModal: false,
    };

    const fetchData = () => {
        const session_token = localStorage.getItem("session_token");
        console.log("fetchData: /users/")
        fetch(BASE_URL + "users/", {
            mode: "cors", headers: {
                "X-Token": session_token
            }
        })
            .then((resp) => resp.json())
            .then((data) => {
                console.log(data);
                if( data["success"] ) {
                    setState( prevState => ({...prevState,
                        isLoading:false, 
                        provisionedUsers: data["provisioned_users"],
                        inactiveUsers: data["inactive_users"],
                        userCount: data["count"],
                        users: data["data"]}));
                }
                else {
                    setState( prevState => ({...prevState,isLoading:false}));
                }
            });
    }

    useEffect( () => {
        if( isLoading ) {
            fetchData();
        }
    })

    const [{isLoading, 
        users, 
        displayInviteUserModal, 
        displayRemoveUserModal, 
        displayAlertModal,
        alertMessage,
        removeUsername, 
        provisionedUsers, 
        inactiveUsers, 
        userCount}, setState] = useState(initialState);

    const handleRemoveUser = (username) => {
        console.log("handleRemoveUser: " + username);
        setState( prevState => ({...prevState, removeUsername: username, displayRemoveUserModal: true}));
    }

    const handleNotifyUser = (username) => {
        console.log("handleNotifyUser: " + username);
    }

    const handleUserDetails = (username) => {
        console.log("handleUserDetails: " + username);
    }

    const handleMakeActive = (username)  => {
        if ((userCount - inactiveUsers) < provisionedUsers) {
            const session_token = localStorage.getItem("session_token");
            const url = BASE_URL + `users/${username}/set_active/`;
            fetch(url, {
                method: "POST",
                mode: "cors",
                headers: {'X-Token': session_token}
            }).then( response => response.json() ).then(data => {
                console.log(data);
                fetchData();
            })
        }
        else {
            setState( prevState => ({...prevState, displayAlertModal:true, alertMessage: "Provisioned user limit exceeded. You must provision more users or inactivate existing users."}))
        }
    }

    const handleCloseInviteUserModal = () => {
        setState( prevState => ({...prevState, displayInviteUserModal: false}));
        fetchData();
    }

    const showInviteUserModal = () => {
        const activeUsers = userCount - inactiveUsers;
        if( activeUsers < provisionedUsers )
        {
            setState( prevState => ({...prevState, displayInviteUserModal: true}));
        }
        else {
            setState( prevState => ({...prevState, displayAlertModal: true, alertMessage: "Provisioned user limit exceeded. You must provision more users or inactivate existing users."}));
        }        
    }

    const confirmRemoveUser = (username) => {
        console.log("confirmRemoveUser: " + username);
        const session_token = localStorage.getItem("session_token");
        const url = BASE_URL + `users/${username}/set_inactive/`;
        console.log("POST " + url);
        fetch(url, {method:"POST",
        mode:"cors",
        headers:{"X-Token": session_token}}).then(response => response.json()).then(data => {
            console.log(data);
            fetchData();
        });
        setState(prevState => ({...prevState, displayRemoveUserModal: false, removeUsername: null}));
    }
    
    
    let topNavigationBar = <NavigationBar />
    let usersTable = "";
    if( isLoading ) {
        return (<div>
            {topNavigationBar}
            <Container className="user_management">
                <Row className="justify-content-md-center"><Spinner animation="border" /></Row>
            </Container>
        </div>
        );
    }
    else {
        usersTable = <UsersTable data={users}
            handleRemoveUser={handleRemoveUser}
            handleNotifyUser={handleNotifyUser}
            handleActivateUser={handleMakeActive}
            handleUserDetails={handleUserDetails} />
    }

    return <div>
        {topNavigationBar}
        <Container className="user_management">
            <Row>
                <Col>
                    <Button onClick={showInviteUserModal} variant="success">New User</Button>
                </Col>
                <Col>
                    <p className="provisioned_users">Provisioned users: {userCount - inactiveUsers} of {provisionedUsers}</p>
                </Col>
            </Row>
            <Row>
                {usersTable}
            </Row>
        </Container>
        <InviteUserModal handleClose={handleCloseInviteUserModal} show={displayInviteUserModal} />
        <RemoveUserModal show={displayRemoveUserModal} 
        confirmRemoveUser={confirmRemoveUser} 
        username={removeUsername}
        handleCancel={() => {
            setState(prevState => ({...prevState, displayRemoveUserModal: false, removeUsername: null}));
        }} />
        <AlertModal show={displayAlertModal}
        message={alertMessage}
        handleCancel={() => {
            setState(prevState => ({...prevState, displayAlertModal: false}));
        }} />
    </div>;
}

export default UserMgmtController;