import React, { useState } from "react";
import { Container, Row, Card, Col, Form, Button, Alert, Spinner } from "react-bootstrap";
import { Link, Navigate, useLocation } from "react-router-dom";

import { BASE_URL, validateUsername, validateEmailAddress } from "../Util";

import TopNavigationBar from "../TopNavigationBar";

function Step1(props) {
    const initialState = { username: "", continueEnabled: false, invalidUsername: false, usernameExists: true, error: null }
    const [{ username,
        continueEnabled,
        invalidUsername,
        usernameExists, error },
        setState] = useState(initialState);

    let submitButton = <Button disabled variant="secondary" type="submit">Continue</Button>;
    if (continueEnabled) {
        submitButton = <Button variant="primary" type="submit">Continue</Button>;
    }

    const location = useLocation();

    const doesUsernameExist = (username) => {
        if (!validateUsername(username)) {
            setState(prevState => ({
                ...prevState,
                invalidUsername: true,
                usernameExists: false,
                continueEnabled: false
            }));
            return;
        }
        console.log("doesUsernameExist");
        const url = BASE_URL + `users/${username}/exists/`;
        fetch(url).then(response => response.json()).then(data => {
            console.log(data);
            if (!data.success) {
                setState(prevState => ({
                    ...prevState,
                    invalidUsername: false,
                    usernameExists: false,
                    continueEnabled: true
                }));
            }
            else {
                setState(prevState => ({
                    ...prevState,
                    invalidUsername: false,
                    usernameExists: true,
                    continueEnabled: false
                }));
            }
        }).catch(function (error) {
            console.log(error);
            setState(prevState => ({ ...prevState, error: "Can't connect to backend." }))
        });
    }

    let alertMessage = "";

    const onFormSubmit = e => {
        e.preventDefault();
        props.nextStep(username);
    }

    if (invalidUsername && username.length > 0) {
        alertMessage = <Alert variant="warning">Invalid username. Username must be start with a letter, contain only letters, numbers and up to 1 underscore. No fewer than 6 or more than 12 characters.</Alert>
    }
    else if (usernameExists && username.length > 0) {
        alertMessage = <Alert variant="warning">Username already registered. Please choose another.</Alert>
    }
    else if (username.length > 0) {
        alertMessage = <Alert variant="success">Username available.</Alert>
    }

    if (error) {
        return <Navigate to="/login" state={{ from: location, message: error }} replace />;
    }

    return (
        <div>
            <TopNavigationBar restrict="login" />
            <Container className="login_page">
                <Card style={{ maxWidth: '30rem' }} className="login_card">
                    <Card.Header><h5>Create new account</h5>
                        <Link to="/login">Back to Login</Link>
                    </Card.Header>
                    <Form onSubmit={onFormSubmit}>
                        <Card.Body>
                            {alertMessage}
                            <Form.Group as={Row} className="mb-3" controlId="forgotPassword">
                                <Form.Label column>Username</Form.Label>
                                <Col sm="8">
                                    <Form.Control onChange={(action) => {
                                        const new_username = action.target.value.toLocaleLowerCase();
                                        doesUsernameExist(new_username);
                                        setState(prevState => ({ ...prevState, username: new_username }));
                                    }} value={username} />
                                </Col>
                            </Form.Group>
                            <Container>
                                <Row className="justify-content-md-center">
                                    {submitButton}
                                </Row>
                            </Container>
                        </Card.Body>

                    </Form>
                </Card>
            </Container>
        </div>
    );
}

function Step2(props) {
    const initialState = {
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        isFormValid: false,
        alerts: [],
        submitting: false,
    }
    const [{ isFormValid,
        alerts,
        firstName,
        lastName,
        emailAddress,
        phoneNumber,
        submitting },
        setState] = useState(initialState);

    let submitButton = <Button variant="secondary" disabled>Continue</Button>
    if (submitting) {
        submitButton = <Button variant="primary" disabled><Spinner animation="border" /></Button>
    }
    else if (isFormValid) {
        submitButton = <Button variant="primary" type="submit">Continue</Button>
    }

    const onFormSubmit = e => {
        e.preventDefault();

        if (validateFields()) {
            const url = BASE_URL + "users/add/"
            const requestBody = {
                username: props.username,
                firstName: firstName,
                lastName: lastName,
                emailAddress: emailAddress,
                phoneNumber: phoneNumber
            };
            setState(prevState => ({...prevState, submitting: true}));
            fetch(url, {
                method: "POST",
                mode: "cors",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(requestBody)
            }).then(response => response.json()).then(data => {
                console.log(data);
                if (data.success) {
                    props.nextStep(emailAddress);
                }
            }).catch(function(error) {
                console.log(error);
                props.handleError("Couldn't create account.");
            });
        }
    }

    const validateFields = () => {
        let emailValid = false;
        let validationAlerts = [];
        if (emailAddress.length > 0) {
            emailValid = validateEmailAddress(emailAddress);
            if (!emailValid) {
                validationAlerts.push("Must enter valid e-mail address.");
            }
        }
        if (validationAlerts.length > 0) {
            setState(prevState => ({ ...prevState, alerts: validationAlerts, isFormValid: false }));
        }
        else {
            setState(prevState => ({ ...prevState, alerts: [], isFormValid: true }));
        }
        return emailValid;
    }

    let alertMessage = alerts.map((eachAlert) => <Alert>{eachAlert}</Alert>);

    return (
        <div>
            <TopNavigationBar restrict="login" />
            <Container className="login_page">
                <Card style={{ maxWidth: '30rem' }} className="login_card">
                    <Card.Header><h5>Create new account</h5>
                        <Link to="/login">Back to Login</Link></Card.Header>
                    <Form onSubmit={onFormSubmit}>
                        <Card.Body>
                            {alertMessage}
                            <Form.Group as={Row} className="mb-3" controlId="firstName">
                                <Form.Label column>First Name</Form.Label>
                                <Col sm="8">
                                    <Form.Control variant="warning" onChange={(action) => { setState(prevState => ({ ...prevState, firstName: action.target.value })); validateFields(); }} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="lastName">
                                <Form.Label column>Last Name</Form.Label>
                                <Col sm="8">
                                    <Form.Control onChange={(action) => { setState(prevState => ({ ...prevState, lastName: action.target.value })); validateFields(); }} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="emailAddress">
                                <Form.Label column>Email Address</Form.Label>
                                <Col sm="8">
                                    <Form.Control onChange={(action) => { setState(prevState => ({ ...prevState, emailAddress: action.target.value })); validateFields(); }} />
                                </Col>
                                <p style={{ textAlign: "right", fontSize: "12px" }} className="text-muted">Required to verify before account creation.</p>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="phoneNumber">
                                <Form.Label column>Phone Number</Form.Label>
                                <Col sm="8">
                                    <Form.Control onChange={(action) => { setState(prevState => ({ ...prevState, phoneNumber: action.target.value })); validateFields(); }} />
                                </Col>
                                <p style={{ textAlign: "right", fontSize: "12px" }} className="text-muted">Optional - for SMS notifications.</p>
                            </Form.Group>
                            <Row className="justify-content-md-center">
                                {submitButton}
                            </Row>
                        </Card.Body>
                    </Form>
                </Card>
            </Container>
        </div>
    );
}

function Step3(props) {
    return (
        <div>
            <TopNavigationBar restrict="login" />
            <Container className="login_page">
                <Card style={{ maxWidth: '30rem' }} className="login_card">
                    <Card.Header><h5>E-Mail Validation</h5><Link to="/login">Back to Login</Link></Card.Header>
                    <Card.Body>
                        <p>An e-mail containing the activation link has been sent to {props.email}</p>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}

function ErrorMessage(props) {
    return (
        <div>
            <TopNavigationBar restrict="login" />
            <Container className="login_page">
                <Card style={{ maxWidth: '30rem' }} className="login_card">
                    <Card.Header><h5>E-Mail Validation</h5><Link to="/login">Back to Login</Link></Card.Header>
                    <Card.Body>
                        <Alert variant="danger">Error: {props.error}</Alert>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}

export default function CreateNewAccount(props) {
    const initialState = { username: null, emailAddress: null, error: null };
    const [{ username, emailAddress, error }, setState] = useState(initialState);

    const handleError = (message) => {
        console.log("handleError: " + message);
        setState(prevState => ({ ...prevState, error: message }));
    }

    if( error ) {
        return <ErrorMessage error={error} />
    }

    if (emailAddress) {
        return <Step3 email={emailAddress} />;
    } else if (username) {
        return <Step2 username={username}
            handleError={handleError}
            nextStep={(emailAddress) => setState(prevState => ({ ...prevState, emailAddress: emailAddress }))} />;
    }
    return (<Step1 nextStep={new_username => setState(prevState => ({ ...prevState, username: new_username }))} />);
}