import React, { useState } from "react";
import { Container, Alert, Badge, Button, Nav, Row, Col, Spinner } from "react-bootstrap";
import { NavigationBar } from "../TopNavigationBar";
import { renderTimeInterval } from "../Util";
import { useGetNotificationsQuery, useUpdateReadNotificationsMutation, useGetNotificationHistoryQuery } from "../../features/api/apiSlice";
import { BsBoxArrowUpRight } from "react-icons/bs";

import PaginationComponent from "../PaginationComponent";

function NotificationItem(props) {
    return (
        <Alert variant="info">
            <p>{props.body}&nbsp;<Badge>{renderTimeInterval(props.timestamp)}</Badge></p>
            <p><Alert.Link target="_blank" href={`/load_details/${props.loadId}`}>Load Details</Alert.Link>&nbsp;<BsBoxArrowUpRight /></p>
        </Alert>
    );
}

function NotificationNavs(props) {
    return (<Container>
        <Row>
            <Col md="auto">
                <Nav variant="pills" defaultActiveKey={props.activeKey}>
                    <Nav.Item><Nav.Link href="/notifications">Inbox&nbsp;{props.unreadCount ? <Badge>{props.unreadCount}</Badge>:null}</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link href="/notifications/history">History</Nav.Link></Nav.Item>
                </Nav>
            </Col>
            <Col md="auto" className="justify-content-md-end mb-2">
                <Button variant="secondary" onClick={props.handleAllMarkNotificationsRead} disabled={props.unreadCount === 0 ? true : false}>Mark All Read</Button>
            </Col>
        </Row>
    </Container>);
}

function LoadingNotifications() {
    return (<div>
        <NavigationBar />
        <Container className="generic_controller">
            <NotificationNavs />
            <Row className="justify-content-md-center"><Spinner animation="border" /></Row>
        </Container>
    </div>);
}

export function NotificationHistoryController() {
    const initialState = { offset: 0, limit: 10, currentPage: 1, totalPages: 1 };
    const [{ offset, limit, currentPage, totalPages }, setState] = useState(initialState);
    const { data, isSuccess, isFetching } = useGetNotificationHistoryQuery({ offset, limit }, { refetchOnMountOrArgChange: true });
    const [updateReadNotifications] = useUpdateReadNotificationsMutation();

    const handleAllMarkNotificationsRead = () => {
        updateReadNotifications();
    }

    const setCurrentPage = (newPage) => {
        if( newPage > 0 && newPage <= totalPages ) {
            const newOffset = (newPage - 1) * limit;
            setState(prevState => ({...prevState, offset: newOffset, limit: limit, currentPage: newPage}));
        }
    }

    if (isFetching) {
        return <LoadingNotifications />
    }

    if (isSuccess && data.success) {
        const modulo = data.count % data.limit;
        const calculatedTotalPages = ((data.limit - modulo) + data.count) / data.limit;
        const calculatedCurrentPage = (data.offset / data.limit) + 1;
        if( calculatedCurrentPage !== currentPage ) {
            setState(prevState => ({...prevState, currentPage: calculatedCurrentPage}));
        }
        if( calculatedTotalPages !== totalPages ) {
            setState(prevState => ({...prevState, totalPages: calculatedTotalPages}));
        }

        const notificationItems = data.data.map((item) =>
            <NotificationItem key={item.NotificationId} body={item.Body} timestamp={item.CreatedTimestamp} loadId={item.LoadId} />);
        return (<div>
            <NavigationBar />
            <Container className="generic_controller">
                <NotificationNavs handleAllMarkNotificationsRead={handleAllMarkNotificationsRead}
                    activeKey="/notifications/history" />
                {notificationItems.length > 0 ? notificationItems : <p>No notifications.</p>}
                {totalPages > 1 ? <PaginationComponent setCurrentPage={setCurrentPage} currentPage={currentPage} totalPages={totalPages} /> : null}
            </Container>
        </div>);
    }

    return (<div>
        <NavigationBar />
        <Container className="generic_controller">
            <NotificationNavs activeKey="/notifications/history" />
            No notifications.
        </Container>
    </div>);
}


export default function NotificationsController() {
    const initialState = { offset: 0, limit: 10, currentPage: 1, totalPages: 1 };
    const [{ offset, limit, currentPage, totalPages }, setState] = useState(initialState);
    const { data, isSuccess, isFetching } = useGetNotificationsQuery({ offset, limit }, { refetchOnMountOrArgChange: true });
    const [updateReadNotifications] = useUpdateReadNotificationsMutation();

    const handleAllMarkNotificationsRead = () => {
        updateReadNotifications();
    }

    const setCurrentPage = (newPage) => {
        if( newPage > 0 && newPage <= totalPages ) {
            const newOffset = (newPage - 1) * limit;
            setState(prevState => ({...prevState, offset: newOffset, limit: limit, currentPage: newPage}));
        }
    }

    if (isFetching) {
        return <LoadingNotifications />
    }

    if (isSuccess && data.success) {
        const unreadCount = data.count;
        const modulo = data.count % data.limit;
        const calculatedTotalPages = ((data.limit - modulo) + data.count) / data.limit;
        const calculatedCurrentPage = (data.offset / data.limit) + 1;
        if( calculatedCurrentPage !== currentPage ) {
            setState(prevState => ({...prevState, currentPage: calculatedCurrentPage}));
        }
        if( calculatedTotalPages !== totalPages ) {
            setState(prevState => ({...prevState, totalPages: calculatedTotalPages}));
        }

        const notificationItems = data.data.map((item) =>
            <NotificationItem key={item.NotificationId} body={item.Body} timestamp={item.CreatedTimestamp} loadId={item.LoadId} />);
        return (<div>
            <NavigationBar />
            <Container className="generic_controller">
                <NotificationNavs handleAllMarkNotificationsRead={handleAllMarkNotificationsRead}
                    activeKey="/notifications"
                    unreadCount={unreadCount} />
                {notificationItems.length > 0 ? notificationItems : <p>No notifications.</p>}
                {totalPages > 1 ? <PaginationComponent setCurrentPage={setCurrentPage} currentPage={currentPage} totalPages={totalPages} /> : null}
            </Container>
        </div>);
    }

    return (<div>
        <NavigationBar />
        <Container className="generic_controller">
            <NotificationNavs activeKey="/notifications" />
            No notifications.
        </Container>
    </div>);
}