import React, { useState } from "react";
import { Container, Spinner, Row, Alert, Card, Form, Col, Button } from "react-bootstrap";
import { BASE_URL } from "../Util";

import { useNavigate, Link } from "react-router-dom";

import TopNavigationBar from "../TopNavigationBar";

function UsernamePrompt(props) {
    const initialState = { username: "", isLoading: false, message: null }
    const [{ username, isLoading, message }, setState] = useState(initialState);

    const onFormSubmit = e => {
        e.preventDefault();
        const url = BASE_URL + `users/${username}/exists/`;
        fetch(url).then(response => response.json()).then(data => {
            console.log(data);
            if (data.success) {
                props.nextStep(username, data.email, data.sms);
            }
            else {
                setState(prevState => ({ ...prevState, isLoading: false, message: "Username not found." }));
            }
        })
    }

    let submitButton = <Button variant="primary" type="submit">Continue</Button>;
    if (isLoading) {
        submitButton = <Button variant="secondary" type="submit" disabled>
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />
        </Button>
    }

    let alerts = "";
    if (message) {
        alerts = <Alert>{message}</Alert>
    }

    return (
        <div>
            <TopNavigationBar restrict="login" />
            <Container className="login_page">
                    <Card style={{ maxWidth: '30rem' }} className="login_card">
                        <Card.Header><h5>Reset Password</h5>
                        <Link to="/login">Back to Login</Link></Card.Header>
                        <Form onSubmit={onFormSubmit}>
                            <Card.Body>
                                {alerts}
                                <Form.Group as={Row} className="mb-3" controlId="forgotPassword">
                                    <Form.Label column>Username</Form.Label>
                                    <Col sm="8">
                                        <Form.Control onChange={(action) => { setState(prevState => ({ ...prevState, username: action.target.value })) }} />
                                    </Col>
                                </Form.Group>
                                <Row className="justify-content-md-center">
                                    {submitButton}
                                </Row>
                            </Card.Body>
                        </Form>
                    </Card>
            </Container>
        </div>

    )

}

function PhoneOrEmailPrompt(props) {
    const initialState = { username: props.username, method: null };
    const [{ username, method }, setState] = useState(initialState);

    const navigate = useNavigate();

    const onFormSubmit = e => {
        e.preventDefault();

        const url = BASE_URL + `users/${username}/forgot_password/${method}/`
        fetch(url).then(response => response.json()).then(data => {
            console.log(data);
            if(data.success) {
                navigate(`/validate/${data.session_token}/`);
            }
        });
    }

    let submitButton = <Button disabled type="submit" variant="secondary">Continue</Button>;
    if (method) {
        submitButton = <Button type="submit" variant="primary">Continue</Button>
    }

    return (
        <div>
            <TopNavigationBar restrict="login" />
            <Container className="login_page">
                <Card style={{ maxWidth: '30rem' }} className="login_card">
                    <Card.Header>                                <h5>Reset Password for {username}</h5>
                        <Link to="/login">Back to Login</Link></Card.Header>
                    <Form onSubmit={onFormSubmit}>
                        <Card.Body>
                            <p>Choose method to receive confirmation code.</p>
                            <div key="select_method">
                                {props.sms ? <Form.Check onChange={() => setState(prevState => ({ ...prevState, method: "sms" }))} label="SMS text message" name="group" type="radio" /> : <Form.Check disabled label="SMS text message" name="group" type="radio" />}
                                {props.email ? <Form.Check onChange={() => setState(prevState => ({ ...prevState, method: "email" }))} label="E-mail" name="group" type="radio" /> : <Form.Check disabled label="E-mail" name="group" type="radio" />}
                            </div>
                            <Row className="justify-content-md-center">
                                {submitButton}
                            </Row>
                        </Card.Body>
                    </Form>
                </Card>
            </Container>
        </div>

    )
}

export default function ForgotPassword(props) {
    const initalState = { username: null, email: false, sms: false };

    const [{ username, email, sms }, setState] = useState(initalState);

    const setUsername = (username, email, sms) => {
        setState(prevState => ({ ...prevState, username: username, email: email, sms: sms }));
    }

    if (!username) {
        return (<UsernamePrompt nextStep={setUsername} />)
    }
    else {
        return <PhoneOrEmailPrompt email={email} sms={sms} username={username} />
    }
}