import { Container, Card, Row, Col, Button, Form, Spinner, Table, Badge } from "react-bootstrap";
import { useState } from "react";

import { NavigationBar } from "../TopNavigationBar";
import LoadingScreen from "../LoadingScreen";
import { renderTimeInterval, formatTimestamp } from "../Util";

import { useParams } from "react-router-dom";

import { useGetLoadDetailQuery, 
    useGetLoadCommentsQuery, 
    useGetLoadHistoryQuery,
usePostDispatchCommentMutation } from "../../features/api/apiSlice";

function CommentCard(props) {
    return (<Card>
        <Card.Header>{props.sender}&nbsp;<Badge bg="info">{renderTimeInterval(props.timestamp)}</Badge></Card.Header>
        <Card.Body>
            {props.body}
        </Card.Body>
    </Card>)
}

function CommentForm(props) {
    const [comment, setComment] = useState("");
    const [postComment] = usePostDispatchCommentMutation();

    const onSubmit = e => {
        e.preventDefault();
        console.log("Posting comment: " + comment);
        postComment({load_id: props.loadId, comment: comment});
    }

    return (<Form onSubmit={onSubmit}>
        <Container fluid>
            <Row>
                <Col md="8">
                    <Form.Control onChange={(e) => setComment(e.target.value)} placeholder="Add new comment" type="textarea" />
                </Col>
                <Col md="4">
                    <Button variant="primary" type="submit">Post</Button>&nbsp;<Button variant="secondary" onClick={() => props.handleClose()}>Cancel</Button>
                </Col>
            </Row>
        </Container>
    </Form>);
}

function LoadComments(props) {
    const initialState = { showCommentForm: false };
    const [{ showCommentForm }, setState] = useState(initialState);

    const { isSuccess, isFetching, data } = useGetLoadCommentsQuery(props.loadId);
    if (isFetching) {
        return (<div>
            <Spinner animation="border" />
        </div>)
    }

    let comments = [];
    if (isSuccess && data.success) {
        comments = data.data.map(item => <CommentCard key={item.CreatedTimestamp} timestamp={item.CreatedTimestamp} sender={item.Sender} body={item.CommentBody} />)
    }

    return (<div>
        <h5>Comments</h5>
        <div className="mb-2">
            {showCommentForm ?
                <CommentForm loadId={props.loadId} handleClose={() => setState(prevState => ({ ...prevState, showCommentForm: false }))} /> :
                <Button variant="primary" onClick={() => setState(prevState => ({ ...prevState, showCommentForm: true }))}>Add Comment</Button>}
        </div>
        {comments}
    </div>);
}

function LoadHistoryItem(props) {
    return (<tr key={props.timestamp}>
        <td key={"event_" + props.timestamp}>{props.event}</td>
        <td key={"username_" + props.timestamp}>{props.username}</td>
        <td key={"timestamp_" + props.timestamp}>{formatTimestamp(props.timestamp)}</td>
    </tr>)
}

function LoadHistory(props) {

    const { data, isSuccess, isFetching } = useGetLoadHistoryQuery(props.loadId);

    if (isFetching) {
        return <div><Spinner animation="border" /></div>
    }

    let tableItems = []
    if (isSuccess && data.success) {
        tableItems = data.data.map(item => <LoadHistoryItem key={item.timestamp} event={item.event} username={item.user} timestamp={item.timestamp} />);
    }

    return (<div><Table>
        <thead>
            <tr key="header">
                <th key="header_status">History</th>
                <th key="header_username">Username</th>
                <th key="header_datetime">Timestamp</th>
            </tr>
        </thead>
        <tbody>
            {tableItems}
        </tbody>
    </Table></div>);
}

export default function LoadDetail() {
    const { load_id } = useParams();

    const { data, isSuccess, isFetching } = useGetLoadDetailQuery(load_id);

    if (isFetching) {
        return <LoadingScreen />
    }

    let loadData = null;

    if (isSuccess && data.success) {
        if (data.success) {
            console.log(data);
            loadData = {
                pickupAddress: data.data.pickup.formatted_address,
                deliveryAddress: data.data.delivery.formatted_address,
                weight: data.data.weight,
                rate: data.data.rate,
                description: data.data.description,
                distance: data.data.distance,
            }
        }
    }

    const formatDistance = (meters, rate) => {
        const meters_to_mile = 0.000621371;
        const miles = (meters_to_mile * meters).toFixed(2);
        const usd_per_mile = (rate / miles).toFixed(2);
        const km = (meters / 1000).toFixed(2);
        const usd_per_km = (rate / km).toFixed(2);
        return `${miles} miles ($${usd_per_mile}/mi) ${km} km ($${usd_per_km}/km)`
    }

    return (<div>
        <NavigationBar />
        <Container className="generic_controller">
            <Card>
                <Card.Header>Load Details</Card.Header>
                <Card.Body>
                    <Row>
                        <Col>Pickup Address</Col>
                        <Col>{loadData.pickupAddress}</Col>
                    </Row>
                    <Row>
                        <Col>Delivery Address</Col>
                        <Col>{loadData.deliveryAddress}</Col>
                    </Row>
                    <Row>
                        <Col>Distance</Col>
                        <Col>{formatDistance(loadData.distance, loadData.rate)}</Col>
                    </Row>
                    <Row>
                        <Col>Rate</Col>
                        <Col>${loadData.rate}</Col>
                    </Row>
                    <Row>
                        <Col>Weight</Col>
                        <Col>{loadData.weight}</Col>
                    </Row>
                    <Row>
                        <Col>Description</Col>
                        <Col>{loadData.description}</Col>
                    </Row>
                </Card.Body>
            </Card>
            <LoadHistory loadId={load_id} />
            <LoadComments loadId={load_id} />
        </Container>
    </div>);
}