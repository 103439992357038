import React, {useState} from "react";
import { Pagination, Container, Modal, Col, Row, Button } from "react-bootstrap";
import { useParams } from "react-router";

import { NavigationBar } from "../TopNavigationBar";
import ExpensesTable from "./ExpensesTable";

import { useUpdateExpenseReportStatusMutation } from "../../features/api/apiSlice";

function ExpensePreviewModal(props) {
    return (<Modal show={props.show} onHide={props.hideModal}>
        <img alt="preview" src={props.previewUrl} />
    </Modal>);
}

function ExpenseConfirmationModal(props) {
    const [updateExpenseReportStatus] = useUpdateExpenseReportStatusMutation();

    const confirmUpdate = () =>
    {
        updateExpenseReportStatus({expense_report_id: props.confirmExpenseId, 
            status: props.confirmationType === "approve" ? "Approved" : "Rejected"});
            props.onExpenseStatusUpdated();
        }

    return (<Modal show={props.show}>
        <Modal.Header>Confirm {props.confirmationType === "approve" ? "approval" : "rejection"}</Modal.Header>
        <Modal.Body>
            <Row>
                <Col>Amount</Col><Col>${props.confirmAmount}</Col>
            </Row>
            <Row>
                <Col>Category</Col><Col>{props.confirmCategory}</Col>
            </Row>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => props.hideModal()}>Cancel</Button>&nbsp;
            {props.confirmationType === "approve" ?<Button onClick={confirmUpdate} variant="success">Approve</Button> : <Button onClick={confirmUpdate} variant="danger">Reject</Button>}
        </Modal.Footer>
    </Modal>)
}

function ExpensesTablePagination(props) {
    if( props.currentPage > 0 && props.totalPages > 0 ) {
        let pageItems = [];
        for( let x = 1; x <= props.totalPages; x += 1 ) {
            pageItems.push(<Pagination.Item href={`/expenses/${x}`} key={x} active={x === props.currentPage}>{x}</Pagination.Item>)
        }
        return (<Pagination>{pageItems}</Pagination>);
    }
    else {
        return <div />;
    }
}

export default function ExpensesController(props) {
    const { page } = useParams();
    let defaultPage = 1;
    if( page !== undefined ) {
        defaultPage = page;
    }
    const defaultLimit = 10;
    const initialState = {
        currentPage: -1, 
        totalPages: -1, 
        offset: (defaultPage - 1) * defaultLimit, 
        limit: defaultLimit,
        showApprovalModal: false,
        showPreviewModal: false,
        confirmationType: null,
        confirmExpenseId: null,
        confirmCategory: null,
        confirmAmount: null,
        confirmDescription: null,
        previewUrl: "",
    };
    const [{currentPage, 
        totalPages, 
        offset, 
        limit,
        showApprovalModal,
        confirmationType,
        confirmExpenseId,
        confirmAmount,
        confirmCategory,
    showPreviewModal,
previewUrl}, setState] = useState(initialState);

    const hideApprovalModal = () => {
        setState(prevState => ({...prevState, showApprovalModal: false}));
    }

    const hidePreviewModal = () => {
        setState(prevState => ({...prevState, showPreviewModal: false, previewUrl: ""}))
    }

    const showPreview = (previewUrl) => {
        setState(prevState => ({...prevState, showPreviewModal: true, previewUrl: previewUrl}))
    }

    const onApprove = (expense_id, category, amount) => {
        setState(prevState => ({...prevState, 
            showApprovalModal: true, 
            confirmationType: "approve", 
            confirmCategory: category,
            confirmAmount: amount,
            confirmExpenseId: expense_id}));
    }

    const onReject = (expense_id, category, amount) => {
        setState(prevState => ({...prevState, 
            showApprovalModal: true, 
            confirmationType: "reject", 
            confirmCategory: category,
            confirmAmount: amount,
            confirmExpenseId: expense_id}));
    }

    const onExpenseStatusUpdated= () => {
        setState(prevState => ({...prevState, showApprovalModal: false}));
    }

    const onViewAttachment = (attachment) => {
        showPreview(attachment)
    }

    const updatePagination = (current_page, total_pages) => {
        setState( prevState => ({...prevState, currentPage: current_page, totalPages: total_pages}));
    }

    return (
        <div>
            <NavigationBar />
            <Container className="expenses_controller">
                <div className="expenses_table">
                    <ExpensesTable onApprove={onApprove}
                        onReject={onReject}
                        onViewAttachment={onViewAttachment}
                        offset={offset}
                        limit={limit}
                        updatePagination={updatePagination} />
                    { totalPages > 1 ? <ExpensesTablePagination
                        currentPage={currentPage}
                        totalPages={totalPages} /> : null}
                </div>
            </Container>
            <ExpenseConfirmationModal show={showApprovalModal}
                confirmationType={confirmationType}
                confirmExpenseId={confirmExpenseId}
                confirmAmount={confirmAmount}
                confirmCategory={confirmCategory}
                hideModal={hideApprovalModal}
                onExpenseStatusUpdated={onExpenseStatusUpdated}
                />
            <ExpensePreviewModal show={showPreviewModal} hideModal={hidePreviewModal} previewUrl={previewUrl} />
        </div>
    );
}