import React, { useState, useEffect } from 'react';
import { Spinner, Table, Button, ListGroup, Modal, Placeholder, Stack } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';

import {BsChatRightFill} from 'react-icons/bs';
import { BASE_URL, formatTimestamp } from '../Util';

function convertDistance(meters, rate) {
    if (meters === 0) {
        return "0.0 mi";
    }
    let miles = (meters / 1609.344);
    if (rate !== undefined) {
        let rate_per_mile = rate / miles;
        return miles.toFixed(1) + " mi ($" + rate_per_mile.toFixed(2) + "/mi)";
    }
    else {
        return miles.toFixed(1) + " mi";
    }
}

function CommentsCell(props) {
    return(<td key={props.loadId + "_comments"}>
        <BsChatRightFill />&nbsp;{props.count}
    </td>);
}

function NoLoadsMessage() {
    return (
        <Stack gap={2} className="col-md-2 mx-auto">
            <p className='mt-5'>No loads to display.</p>
            </Stack>);
}

function ActionsCell( props ) {
    if (props.selectedMenuItem === "cancelled") {
        return (<td key={props.loadId + "_actions"}>
            <Button size="sm" variant="primary" onClick={() => { props.onViewLoad(props.loadId) }}>Details</Button>
        </td>);
    }
    else if (props.selectedMenuItem === "unassigned") {
       return (<td key={props.loadId + "_actions"}>
            <Button size="sm" variant="success" onClick={() => { props.showAssignModal(props.loadId) }}>Assign</Button>&nbsp;
            <Button size="sm" variant="primary" onClick={() => { props.onEditLoad(props.loadId) }}>Edit</Button>&nbsp;
            <Button size="sm" variant="danger" onClick={() => { props.onCancelLoad(props.loadId) }}>Cancel</Button>
        </td>); 
    }
    else if( props.selectedMenuItem === "assigned") {
        return (<td key={props.loadId + "_actions"}>
            <Button size="sm" onClick={() => { props.onRemoveAssignedDriver(props.loadId) }} variant="danger">Remove Driver</Button>&nbsp;
            <Button size="sm" onClick={() => {props.onViewLoad(props.loadId)}}>Details</Button>
        </td>)
    }
    else if( props.selectedMenuItem === "completed")  {
        return (<td key={props.loadId + "_actions"}>
            <Button size="sm" onClick={() => props.onInvoiceLoad(props.loadId) } variant="success">Invoice</Button>&nbsp;
            <Button size="sm" onClick={() => { props.onViewLoad(props.loadId) }}>Details</Button>
        </td>);
    }
    else if( props.selectedMenuItem === "invoiced") {
        return(<td key={props.loadsData + "_actions"}>
            <Button size="sm" onClick={() => props.onPaidLoad(props.loadId)} variant="success">Paid</Button>&nbsp;
            <Button size="sm" variant="primary" onClick={() => { props.onViewLoad(props.loadId) }}>Details</Button>
        </td>);
    }
    else if( props.selectedMenuItem === "active") {
        return (<td key={props.loadId + "_actions"}>
            <Button size="sm" variant="primary" onClick={() => { props.onViewLoad(props.loadId) }}>Details</Button>
        </td>);
    }
    else if( props.selectedMenuItem === "paid") {
        return (<td key={props.loadId + "_actions"}>
            <Button size="sm" variant="primary" onClick={() => { props.onViewLoad(props.loadId) }}>Details</Button>
        </td>);
    }
    else {
        return (<td key={props.loadId + "_actions"}></td>);
    }
}

function DriversForLoad(props) {
    let listItems = [];

    const onClick = (username) => { props.onAssignLoad(username) }

    if( props.driversData ){
        for (let eachItem of props.driversData) {
            let itemText = eachItem.username + " (" + eachItem.full_name + ")";
            listItems.push(<ListGroup.Item key={eachItem.username} action onClick={() => { onClick(eachItem.username) }}>{itemText}</ListGroup.Item>);
        }
    }
    else {
        listItems.push(<ListGroup.Item key="0"><Placeholder sm="7" /></ListGroup.Item>)
    }
    
    return (
        <ListGroup>{listItems}</ListGroup>
    );
}

function AssignDriverModal(props) {
    const initialState = {loading: true, drivers: null};
    const [{loading, drivers}, setState] = useState(initialState);

    const assignLoad = (username) => {
        if(props.loadId) {
            props.onAssignLoad(props.loadId, username);
        }
    }

    let url = null;
    if(props.loadId) {
        url = BASE_URL + "drivers/load/" + props.loadId;
    }
    const session_token = localStorage.getItem("session_token");
    const fetchData = async () => {
        console.log("GET " + url);
        try {
            const response = await fetch(url, {
                headers: {
                    "X-Token": session_token
                }
            });
            const json = await response.json();
            if (json.success) {
                setState(prevState => ({ ...prevState, loading: false, drivers:json.data }));
            }
        }
        catch (error) {
            console.log(error);
            setState(prevState => ({ ...prevState, loading: false }));
        }
    };
    useEffect(() => {
        if( url && !drivers ) {
            fetchData();
        }
    })

    let modalHeader = <Modal.Header><Modal.Title>Assign Driver</Modal.Title><Spinner animation="border" /></Modal.Header>;
    if(!loading) {
        modalHeader = <Modal.Header><Modal.Title>Assign Driver</Modal.Title></Modal.Header>;
    }

    return (<Modal show={props.show} size="sm" onHide={props.onHide}>
        {modalHeader}
        <Modal.Body><DriversForLoad driversData={drivers} onAssignLoad={assignLoad} /></Modal.Body>
        <Modal.Footer><Button onClick={props.onHide} variant="secondary">Cancel</Button></Modal.Footer>
    </Modal>);
}

function LoadsTable(props) {
    const initialState = { loadId: null, showModal: false };
    const [{ loadId, showModal }, setState] = useState(initialState);
    const navigate = useNavigate();

    const viewLoad = (load_id) => {
        navigate("/load_details/" + load_id);
    }

    const showAssignModal = (load_id) => {
        setState({ loadId: load_id, showModal: true });
    }

    const hideModal = () => {
        setState({ ...initialState });
    }

    const assignDriverForLoad = (load_id, driver) => 
    { 
        props.onAssignLoad(driver, load_id);
        hideModal();
    }

    if (props.loading) {
        return <div className="loadsTable">
                <div className="loadingSpinner">
            <Spinner animation="border" />
            </div>
        </div>;
    }
    let tableRows = [];
    for (let eachItem of props.loadsData) {
        let newRow = [];
        console.log(eachItem);
        newRow.push(<td key={eachItem.loadId + "_no"}>{eachItem.load_number}</td>);
        newRow.push(<td key={eachItem.loadId + "_rate"}>{eachItem.rate}</td>);
        newRow.push(<td key={eachItem.loadId + "_weight"}>{eachItem.weight}</td>);
        newRow.push(<td key={eachItem.loadId + "_length"}>{eachItem.length}</td>);
        newRow.push(<td key={eachItem.loadId + "_distance"}>{convertDistance(eachItem.distance, eachItem.rate)}</td>);
        newRow.push(<td key={eachItem.loadId + "_description"}>{eachItem.description}</td>);
        newRow.push(<CommentsCell loadId={eachItem.load_id} 
            loadNumber={eachItem.load_number} 
            count={eachItem.comment_count} 
            onViewComments={props.onViewComments} />)
        if (props.selectedMenuItem === "cancelled") {
            newRow.push(<td key={eachItem.loadId + "_actions"}>
                <Button variant="primary" onClick={() => { viewLoad(eachItem.load_id) }}>Details</Button>
            </td>);
        }
        else if (props.selectedMenuItem === "unassigned") {
            let createdTimestamp = new Date(eachItem.created_timestamp);
            newRow.push(<td key={eachItem.loadId + "_created"}>
                {createdTimestamp.toLocaleString()}
            </td>);
            newRow.push(<td key={eachItem.loadId + "_dispatcher"}>{eachItem.dispatcher}</td>);
            newRow.push(<ActionsCell showAssignModal={showAssignModal} 
                onEditLoad={props.onEditLoad} 
                onCancelLoad={props.onCancelLoad} 
                onViewLoad={viewLoad} 
                selectedMenuItem={props.selectedMenuItem} 
                onRemoveAssignedDriver={props.onRemoveAssignedDriver}
                loadId={eachItem.load_id} />);
        }
        else if( props.selectedMenuItem === "assigned") {
            newRow.push(<td key={eachItem.loadId + "_dispatcher"}>{eachItem.dispatcher}</td>);
            newRow.push(<td key={eachItem.loadId + "_driver"}>{eachItem.driver}</td>);
            newRow.push(<ActionsCell showAssignModal={showAssignModal} 
                onEditLoad={props.onEditLoad} 
                onCancelLoad={props.onCancelLoad} 
                onViewLoad={viewLoad} 
                selectedMenuItem={props.selectedMenuItem} 
                onRemoveAssignedDriver={props.onRemoveAssignedDriver}
                loadId={eachItem.load_id} />);
        }
        else if( props.selectedMenuItem === "completed")  {
            newRow.push(<td key={eachItem.loadId + "_dispatcher"}>{eachItem.dispatcher}</td>);
            newRow.push(<td key={eachItem.loadId + "_driver"}>{eachItem.driver}</td>);
            newRow.push(<td key={eachItem.loadId + "_pull"}>{formatTimestamp(eachItem.pull_timestamp)}</td>);
            newRow.push(<td key={eachItem.loadId + "_drop"}>{formatTimestamp(eachItem.drop_timestamp)}</td>);
            newRow.push(<ActionsCell showAssignModal={showAssignModal} 
                onEditLoad={props.onEditLoad} 
                onCancelLoad={props.onCancelLoad} 
                onViewLoad={viewLoad}
                selectedMenuItem={props.selectedMenuItem} 
                onRemoveAssignedDriver={props.onRemoveAssignedDriver}
                onInvoiceLoad={props.onInvoiceLoad}
                loadId={eachItem.load_id} />);
        }
        else if( props.selectedMenuItem === "active") {
            newRow.push(<td key={eachItem.loadId + "_driver"}>{eachItem.driver}</td>);
            newRow.push(<ActionsCell showAssignModal={showAssignModal} 
                onEditLoad={props.onEditLoad} 
                onCancelLoad={props.onCancelLoad} 
                onViewLoad={viewLoad}
                selectedMenuItem={props.selectedMenuItem} 
                onRemoveAssignedDriver={props.onRemoveAssignedDriver}
                loadId={eachItem.load_id} />);
        }
        else if( props.selectedMenuItem === "invoiced") {
            newRow.push(<ActionsCell showAssignModal={showAssignModal} 
                onEditLoad={props.onEditLoad} 
                onCancelLoad={props.onCancelLoad} 
                onViewLoad={viewLoad}
                selectedMenuItem={props.selectedMenuItem} 
                onRemoveAssignedDriver={props.onRemoveAssignedDriver}
                onPaidLoad={props.onPaidLoad}
                loadId={eachItem.load_id} />);
        }
        else if( props.selectedMenuItem === "paid") {
            newRow.push(<ActionsCell showAssignModal={showAssignModal} 
                onEditLoad={props.onEditLoad} 
                onCancelLoad={props.onCancelLoad} 
                onViewLoad={viewLoad}
                selectedMenuItem={props.selectedMenuItem} 
                onRemoveAssignedDriver={props.onRemoveAssignedDriver}
                onPaidLoad={props.onPaidLoad}
                loadId={eachItem.load_id} />);
        }
        tableRows.push(<tr key={eachItem.load_id}>{newRow}</tr>);
    }

    let tableHeadings = [];
    tableHeadings.push(<th>#</th>);
    tableHeadings.push(<th>Rate</th>);
    tableHeadings.push(<th>Weight</th>);
    tableHeadings.push(<th>Length</th>);
    tableHeadings.push(<th>Distance</th>);
    tableHeadings.push(<th>Description</th>);
    tableHeadings.push(<th></th>);
    if( props.selectedMenuItem === "unassigned" ) {
        tableHeadings.push(<th>Created</th>);
        tableHeadings.push(<th>Dispatcher</th>);
    }
    if( props.selectedMenuItem === "assigned" ) {
        tableHeadings.push(<th>Dispatcher</th>);
        tableHeadings.push(<th>Driver</th>);
    }
    else if( props.selectedMenuItem === "completed")  {
        tableHeadings.push(<th>Dispatcher</th>);
        tableHeadings.push(<th>Driver</th>);
        tableHeadings.push(<th>Pickup</th>);
        tableHeadings.push(<th>Delivery</th>);
    }
    else if( props.selectedMenuItem === "active") {
        tableHeadings.push(<td>Driver</td>);
    }
    tableHeadings.push(<th></th>);


    return (
        <div className="loadsTable">
            <Table striped size="sm" responsive>
                <thead><tr key="header">{tableHeadings}</tr></thead>
                <tbody>{tableRows}</tbody>
            </Table>
            {tableRows.length === 0 ? <NoLoadsMessage /> : null}
            <AssignDriverModal key={loadId} loadId={loadId} show={showModal} onAssignLoad={assignDriverForLoad} onHide={hideModal} />
        </div>);
}

export default LoadsTable;