export const BASE_URL = "/api/";

export function formatTimestamp(iso_format) {
    // convert from UTC to local time
    if( iso_format.slice(-1) === "Z" ) {
        return new Date(iso_format).toLocaleString();
    }
    return new Date(iso_format + "Z").toLocaleString();
}

export function validatiateCompanyName(companyName) {
    const companyNameRegex = /^[A-Z]{1}[A-Za-z0-9 ]{4,35}$/;
    return companyNameRegex.exec(companyName);
}

export function validatePhoneNumber(phoneNumber) {
    const phone_number_re1 = /^\(\d{3}\)\d{3}-\d{4}$/;
    const phone_number_re2 = /^(?:\d{3}|\(\d{3}\))([-/.])\d{3}\1\d{4}$/;
    const phone_number_re3 = /^\d{10}/;

    let phoneNumberValid = false;
    if (phoneNumber !== "") {
        phoneNumberValid = phone_number_re1.exec(phoneNumber);
        if (!phoneNumberValid) {
            phoneNumberValid = phone_number_re2.exec(phoneNumber);
        }
        if (!phoneNumberValid) {
            phoneNumberValid = phone_number_re3.exec(phoneNumber);
        }
        return phoneNumberValid;
    }
    return false;
}

export function validateEmailAddress(emailAddress) {
    const email_re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if( emailAddress !== "") {
        return email_re.exec(emailAddress.toLowerCase());
    }
    return false;
}

export function renderTimeInterval(iso_timestamp) {
    let timestamp = null;
    if( iso_timestamp.slice(-1) === "Z" ) {
        timestamp = new Date(iso_timestamp).toLocaleString();
    }
    else {
        timestamp = new Date(iso_timestamp + "Z").toLocaleString();
    }
    
    const delta_t = new Date() - timestamp;
    let output = timestamp.toLocaleString();

    let days = delta_t / 86400000;
    let hours = delta_t / 3600000;
    let minutes = delta_t / 60000;
    if( days > 1 ) {
        if( days > 7 ) {
            return output;
        }
        else if( days > 2 ) {
            output = days.toFixed(0) + " days ago";
        }
        else {
            output = "1 day ago"
        }
    }
    else if( hours > 1 ) {
        if( hours > 2 ) {
            output = hours.toFixed(0) + " hours ago";
        }
        else {
            output = "1 hour ago"
        }
    }
    else if( minutes > 1 ) {
        if( minutes > 2 ) {
            output = minutes.toFixed(0) + " minutes ago";
        }
        else {
            output = "1 minute ago"
        }
    }
    else {
        output = "just now";
    }
    return output;
}

export function validateUsername(username) {
    if( username.match(/^[a-z]{1}[a-z0-9_]{5,12}$/) ) {
        return true;
    }
    return false;
}