import React, { useState } from "react";

import { Row, Col, Button, Modal, Form, Alert, Spinner } from "react-bootstrap";

import { BASE_URL } from "../Util";

export default function ChangePasswordModal(props) {
    const initialState = { old_password: "", new_password: "", new_password_repeat: "", isLoading: false, alerts: [] };
    const [{ old_password, new_password, new_password_repeat, isLoading, alerts }, setState] = useState(initialState);

    const closeModal = () => {
        setState(initialState);
        props.handleClose();
    }

    const postRequest = () => {
        console.log("fetchData: /users/password/reset/")
        const session_token = localStorage.getItem("session_token");
        const requestObject = {
            user_id: props.username,
            new_password: new_password,
            repeat_password: new_password_repeat,
            old_password: old_password
        }
        setState(prevState => ({ ...prevState, isLoading: true }));
        fetch(BASE_URL + "users/password/reset/", {
            method: "POST",
            mode: "cors", headers: {
                "X-Token": session_token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestObject)

        })
            .then((resp) => resp.json())
            .then((data) => {
                console.log(data);
                if (data["success"]) {
                    setState(initialState);
                    props.handleClose();
                }
                else {
                    setState(prevState => ({ ...prevState, isLoading: false, alerts:[data["message"]] }));
                }
            });
    }

    const validate = () => {
        let validationAlerts = []
        if (old_password === "") {
            validationAlerts.push("Enter existing password.");
        }
        if (new_password === "") {
            validationAlerts.push("Enter new password.");
        }
        else if (new_password !== new_password_repeat) {
            validationAlerts.push("New password didn't match confirmation.");
        }
        if( validationAlerts.length === 0 ) {
            postRequest();
        }
        setState( prevState => ({...prevState, alerts:validationAlerts}));
    }

    let alertMessages = [];
    for (let each of alerts) {
        alertMessages.push(<Alert variant="warning">{each}</Alert>);
    }

    let submitButton = <Button onClick={validate}>Change Password</Button>;
    if( isLoading ) {
        submitButton = <Button disabled>
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            />
        </Button>;
    }

    return (
        <Modal show={props.show}>
            <Modal.Header>Change password</Modal.Header>
            <Modal.Body>
            {alertMessages}
                <Form.Group as={Row} className="mb-3" controlId="oldPassword">
                    <Form.Label column>Old password</Form.Label>
                    <Col sm="8">
                        <Form.Control onChange={(action) => { setState(prevState => ({ ...prevState, old_password: action.target.value })) }} type="password" />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="newPassword">
                    <Form.Label column>New password</Form.Label>
                    <Col sm="8">
                        <Form.Control onChange={(action) => { setState(prevState => ({ ...prevState, new_password: action.target.value })) }} type="password" />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="newPasswordRepeat">
                    <Form.Label column>Repeat new password</Form.Label>
                    <Col sm="8">
                        <Form.Control onChange={(action) => { setState(prevState => ({ ...prevState, new_password_repeat: action.target.value })) }} type="password" />
                    </Col>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={closeModal} variant="secondary">Cancel</Button>&nbsp;{submitButton}
            </Modal.Footer>
        </Modal>
    )
}