import React, { useState } from "react";
import { Table, Button, Spinner, Row } from "react-bootstrap";

import { useGetExpenseReportsForAccountQuery } from "../../features/api/apiSlice";

import { formatTimestamp } from "../Util";

function ViewAttachmentCell(props) {
    if( props.attachment ) {
        return (
            <td key={props.id + "_view_attachment"}>
                <Button onClick={() => props.onViewAttachment(props.s3PrefixUrl + props.attachment)} size="sm" variant="secondary">View</Button>
            </td>
        )
    }
    return (<td key={props.id + "_view_attachment"}></td>);
}

function ExpenseStatusCell(props) {
    if( props.status === "Submitted" ) {
        return (<td key={props.id + "_status"}>
            <Button size="sm" onClick={() => props.onApprove(props.id, props.category, props.amount)} variant="success">Approve</Button>&nbsp;
            <Button size="sm" onClick={() => props.onReject(props.id, props.category, props.amount)} variant="danger">Reject</Button>
        </td>);
    }

    return (<td key={props.id + "_status"}>
        {props.status}
    </td>)
}

export function ExpensesTableRow(props) {

    return (<tr key={props.id}>
        <ExpenseStatusCell onApprove={props.onApprove}
            onReject={props.onReject}
            status={props.status}
            amount={props.amount}
            category={props.category}
            id={props.id} />
        <td key={props.id + "_timestamp"}>{formatTimestamp(props.timestamp)}</td>
        <td key={props.id + "_username"}>{props.username}</td>
        <td key={props.id + "_category"}>{props.category}</td>
        <td key={props.id + "_amount"}>${props.amount}</td>
        <ViewAttachmentCell id={props.id}
            attachment={props.attachment}
            onViewAttachment={props.onViewAttachment}
            s3PrefixUrl={props.s3PrefixUrl} />
        <td key={props.id + "_description"}>{props.description}</td>
    </tr>);
}

export default function ExpensesTable(props) {

    const initialState = {loading: true, tableData: [], s3PrefixUrl: ""};
    const [{loading, tableData, s3PrefixUrl}, setState] = useState(initialState);
    const offset = props.offset;
    const limit = props.limit;
    const { data, isSuccess, isFetching } = useGetExpenseReportsForAccountQuery({offset, limit},{refetchOnMountOrArgChange:true});

    console.log("ExpensesTable isFetching: " + isFetching);
    if( isFetching && !loading ) {
        console.log("setting loading to true");
        setState( prevState => ({...prevState, loading: true, tableData: []}))
    }

    if( isSuccess && data["success"] && loading && !isFetching ) {
        const modulo = data.count % data.limit;
        const totalPages = ((data.limit - modulo) + data.count) / data.limit;
        const currentPage = (data.offset / data.limit) + 1;
        props.updatePagination(currentPage, totalPages);
        setState( prevState => ({...prevState, loading: false, tableData: data.data, s3PrefixUrl: data.s3_bucket_url}));
    }
    if( loading ) {
        return <div><Row className="justify-content-md-center"><Spinner animation="border" /></Row></div>
    }

    const tableItems = tableData.map(item => <ExpensesTableRow id={item.ExpenseReportId} 
            status={item.Status} 
            timestamp={item.Timestamp}
            username={item.Username}
            category={item.Category}
            amount={item.Amount}
            attachment={item.ImagePath}
            description={item.Description}
            onApprove={props.onApprove}
            onReject={props.onReject}
            onViewAttachment={props.onViewAttachment}
            s3PrefixUrl={s3PrefixUrl}
            />);

    console.log("render ExpensesTable");

    return (
        <Table striped size="sm" responsive>
            <thead>
                <tr key="header">
                    <th key="header_status">Status</th>
                    <th key="header_datetime">Datetime</th>
                    <th key="header_username">Username</th>
                    <th key="header_category">Category</th>
                    <th key="header_amount">Amount</th>
                    <th key="header_attachment">Attachment</th>
                    <th key="header_description">Description</th>
                </tr>
            </thead>
            <tbody>
                {tableItems}
            </tbody>
        </Table>
    );
}