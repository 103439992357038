import React from "react";

import { Table, Badge, Button } from 'react-bootstrap';

function VerifiedCell(props) {
    if (props.isVerified) {
        return (<td key={props.key + "_" + props.colId}>
            {props.value}&nbsp;<Badge pill bg="success">Verified</Badge>
        </td>);
    }
    return (<td key={props.key + "_" + props.colId}>
        {props.value}&nbsp;<Badge pill bg="warning">Unverified</Badge>
    </td>);
}

function ActionsCell(props) {
    if( props.isOwner ) {
        return (<td key={props.key + "_actions"}>
            <Button variant="secondary">Details</Button>
        </td>);
    }
    if( props.isInactive ) {
        return (<td key={props.key + "_actions"}>
            <Button variant="secondary">Message</Button>&nbsp;
            <Button variant="secondary">Details</Button>&nbsp;
            <Button onClick={() => { props.handleActivateUser(props.username)}} variant="success">Set Active</Button>
        </td>);
    }
    return (<td key={props.key + "_actions"}>
        <Button variant="secondary">Message</Button>&nbsp;<Button variant="secondary">Details</Button>&nbsp;<Button onClick={() => { props.handleRemoveUser(props.username)}} variant="danger">Set Inactive</Button>
        </td>);
}

function RolesCell(props) {
    let roles = [];
    console.log(props.roles)
    if( props.roles.includes("Owner") ) {
        roles.push(<div key={props.key + "_owner"}><Badge bg="info">Owner</Badge></div>);
    }
    if( props.roles.includes("Driver") ){
        roles.push(<div key={props.key + "_driver"}><Badge bg="info">Driver</Badge></div>);
    }
    if( props.roles.includes("Dispatcher") ) {
        roles.push(<div key={props.key + "_dispatcher"}><Badge bg="info">Dispatcher</Badge></div>);
    }
    return (<td key={props.key + "_roles"}>
        {roles}
    </td>)
}

function UsersTable(props) {

    let tableRows = [];
    let x = 0;
    for (let eachItem of props.data) {
        x += 1;
        console.log(eachItem);
        const isOwner = eachItem.roles.includes("Owner");
        tableRows.push(<tr key={x}>
            <ActionsCell key={x} username={eachItem.username} isOwner={isOwner} isInactive={eachItem.status === "Inactive" ? true : false} handleRemoveUser={props.handleRemoveUser} handleActivateUser={props.handleActivateUser} />
            <td key={x + "_username"}>{eachItem.username}</td>
            <td key={x + "_fullname"}>{eachItem.firstName + " " + eachItem.lastName}</td>
            <VerifiedCell isVerified={eachItem.phoneNumberVerified} value={eachItem.phoneNumber} key={x} colId="phoneNumber" />
            <VerifiedCell isVerified={eachItem.emailVerified} value={eachItem.email} key={x} colId="emailAddress" />
            <td key={x + "_status"}>{eachItem.status}</td>
            <RolesCell roles={eachItem.roles} key={x} />
        </tr>)
    }

    return (<div className="usersTable">
        <Table striped size="sm" responsive>
            <thead>
                <tr key="header">
                    <th>Actions</th>
                    <th>Username</th>
                    <th>Name</th>
                    <th>Mobile Phone (SMS)</th>
                    <th>E-mail</th>
                    <th>Status</th>
                    <th>Roles</th>
                </tr>
            </thead>
            <tbody>
                {tableRows}
            </tbody>
        </Table>
    </div>);
}

export default UsersTable;